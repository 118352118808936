<div
  *ngIf="userNotifications.length; else noNotificationsFound"
  class="user-notifications"
>
  <form
    (ngSubmit)="onSubmit()"
    [formGroup]="userNotificationsForm"
  >
    <div>
      <div
        *ngFor="let notificationTopic of userNotifications"
        class="notification-option"
      >
        <div class="user-notification-label">
          {{ notificationTopic.topicDescription }}
        </div>
        <div class="">
          <pinnakl-input
            [controlName]="notificationTopic.topicName"
            [form]="userNotificationsForm"
            checkBoxStyle="toggle"
            type="boolean"
          >
          </pinnakl-input>
        </div>
      </div>
    </div>
    <div class="row justify-content-between m-0 p-0">
      <div>
        <button
          #anchor
          (click)="showFormCancelConfirmation()"
          [disabled]="!userNotificationsForm.dirty"
          class="btn btn-security btn-cancel"
          type="button"
        >
          CANCEL
        </button>
        <confirm-action
          (onCancelled)="cancelReset()"
          (onConfirmed)="resetForm()"
          [anchor]="anchor"
          [showConfirmation]="cancelConfirmationVisible"
          confirmationMessage="Are you sure you want to reset the form?"
        >
        </confirm-action>
      </div>
      <div>
        <button
          [disabled]="!userNotificationsForm.dirty"
          class="btn btn-security btn-save"
          type="submit"
        >
          SAVE
        </button>
      </div>
    </div>
  </form>
</div>
<ng-template #noNotificationsFound>
  <div *ngIf="!isLoading">
    You are not subscribed to any topics in Pinnakl. Please reach out to Pinnakl support.
  </div>
</ng-template>
