import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as vdrUsers from './vdr-users/vdr-users.reducer';

export interface State {
  vdrUsers: vdrUsers.State;
}

export const reducers: ActionReducerMap<State> = {
  vdrUsers: vdrUsers.reducer
};

const selectVdrFeature = createFeatureSelector<State>('vdrUsers');

const selectVdrUsersState = createSelector(selectVdrFeature, (state: State) => state.vdrUsers);

export const selectAllVdrUsers = createSelector(selectVdrUsersState, vdrUsers.selectAll);

export const selectVdrUserById = id =>
  createSelector(selectAllVdrUsers, entities =>
    entities.find(vdrUser => vdrUser.portalUserId === id)
  );

export const selectVdrUsersLoaded = createSelector(selectVdrUsersState, vdrUsers.selectLoaded);

export const selectVdrUsersLoading = createSelector(selectVdrUsersState, vdrUsers.selectLoading);
