import { createAction, props } from '@ngrx/store';
import { CrmVdrConfig } from '@pinnakl/vdr/domain';

export enum VdrConfigActionTypes {
  LoadVdrConfig = '[VDR Config] Load VDR Config',
  LoadVdrConfigError = '[VDR Config] Load VDR Config Error',
  SetVdrConfig = '[VDR Config] Set VDR Config'
}

export const LoadVdrConfig = createAction(VdrConfigActionTypes.LoadVdrConfig);
export const LoadVdrConfigError = createAction(
  VdrConfigActionTypes.LoadVdrConfigError,
  props<{ error: any }>()
);
export const SetVdrConfig = createAction(
  VdrConfigActionTypes.SetVdrConfig,
  props<{ config: CrmVdrConfig }>()
);
