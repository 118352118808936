import { createAction, props } from '@ngrx/store';
import { VdrUserWithClientConfig } from '@pinnakl/vdr/domain';

export enum VdrUsersActionTypes {
  LoadVdrUsers = '[VDR Users] Load VDR Users',
  SetVdrUsers = '[VDR Users] Set VDR Users',
  UpdateVdrUsers = '[VDR Users] Update VDR Users',
  DeleteVdrUsers = '[VDR Users] Delete VDR Users'
}

export const LoadVdrUsers = createAction(VdrUsersActionTypes.LoadVdrUsers);
export const SetVdrUsers = createAction(
  VdrUsersActionTypes.SetVdrUsers,
  props<{ vdrUsers: VdrUserWithClientConfig[] }>()
);
export const UpdateVdrUsers = createAction(
  VdrUsersActionTypes.UpdateVdrUsers,
  props<{ vdrUsers: VdrUserWithClientConfig[] }>()
);
export const DeleteVdrUsers = createAction(
  VdrUsersActionTypes.DeleteVdrUsers,
  props<{ vdrUserIds: number[] }>()
);
