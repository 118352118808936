import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomAttribute } from '@pinnakl/shared/custom-attributes/domain';
import { PrimeButtonComponent } from '@pinnakl/shared/ui/prime';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';
import { ICONS_MAP } from '../custom-attributes-utils.helpers';

@Component({
  standalone: true,
  imports: [PrimeButtonComponent, InlineSVGModule, NgIf],
  selector: 'custom-attribute-row-item',
  templateUrl: './custom-attribute-row-item.component.html',
  styleUrls: ['./custom-attribute-row-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomAttributeRowItemComponent {
  @Input() customAttribute: CustomAttribute | null = null;
  @Input() withMandatoryColumn = false;
  @Output() edited = new EventEmitter<CustomAttribute>();
  @Output() deleted = new EventEmitter<number>();
  readonly iconsMap = ICONS_MAP;
}
