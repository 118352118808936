import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDragPlaceholder,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray
} from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';

@Component({
  standalone: true,
  selector: 'custom-attribute-list-options',
  templateUrl: './custom-attribute-list-options.component.html',
  styleUrls: ['./custom-attribute-list-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InlineSVGModule,
    CdkDropList,
    CdkDrag,
    CdkDropListGroup,
    CdkDragPlaceholder,
    CdkDragHandle
  ]
})
export class CustomAttributeListOptionsComponent {
  @Input() items: string[] = [];
  @Output() listOptionsChange = new EventEmitter<string[]>();
  @Output() listOptionDeleted = new EventEmitter<number>();

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.listOptionsChange.emit(this.items);
  }

  optionDeleted(index: number): void {
    this.listOptionDeleted.emit(index);
    this.items.splice(index, 1);
  }
}
