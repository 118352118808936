<div class="crm-settings">
  <div class="blockedIps-list">
    <div class="title">Blocked IPs</div>

    <div>
      <div class="new-blocked-ip">
        <div class="input">
          <pinnakl-input-floating-label
            [(ngModel)]="newBlockedIp"
            label="Comments"
            type="text"
          >
          </pinnakl-input-floating-label>
          <span
            *ngIf="!checkValidNewIp() && this.newBlockedIp"
            class="error-message"
          >
            <span
              ><svg
                height="12"
                viewBox="0 0 12 12"
                width="12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g>
                    <g>
                      <g>
                        <g
                          transform="translate(-727 -300) translate(669 160) translate(30 114) translate(28 26)"
                        >
                          <circle
                            cx="6"
                            cy="6"
                            fill="#FF9213"
                            r="6"
                          />
                          <path
                            d="M5 2H7V7H5zM5 8H7V10H5z"
                            fill="#FFF"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            <span>IP Address is not valid.</span>
          </span>
        </div>
        <button
          (click)="addEntity()"
          class="add-new-button btn btn-custom-blue-design"
        >
          Add
        </button>
      </div>
      <div
        *ngFor="let item of blockedIps"
        class="input-container"
      >
        <div class="input">
          <span class="value">{{ item?.ipaddress }}</span>
        </div>
        <i
          (click)="removeEntity(item)"
          class="icon-pinnakl-cancel btn-close-icon"
        ></i>
      </div>
    </div>
  </div>
</div>
