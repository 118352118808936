import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EMAIL_PATTERN } from '@pinnakl/shared/constants';
import { FileSizes } from '@pinnakl/shared/domain-components';
import { User } from '@pinnakl/shared/types';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { FileTypeFormat, allowedFileTypeFormats } from '@pinnakl/vdr-shared';

@Component({
  selector: 'test-email-template-modal',
  templateUrl: './test-email-template-modal.component.html',
  styleUrls: ['./test-email-template-modal.component.scss']
})
export class TestEmailTemplateModalComponent implements OnInit {
  maxFileSize = FileSizes.MB10;
  form: UntypedFormGroup;
  users: User[];
  submitted = false;
  hideEmailsErrorMsg = true;
  types = [FileTypeFormat.PPT, FileTypeFormat.XLSX, FileTypeFormat.PDF];
  draggedFile;
  accessFormats = [];
  selectedFile;

  get emails() {
    return this.form.controls['emails'] as UntypedFormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: {
      data: {
        users: User[];
      };
    },
    readonly dialogRef: MatDialogRef<TestEmailTemplateModalComponent>,
    private readonly fb: UntypedFormBuilder,
    private readonly toastr: PinnaklUIToastMessage
  ) {
    Object.assign(this, this.data.data);
  }

  ngOnInit(): void {
    this.initForm();
    this.initAccessTypes();
  }

  initForm() {
    this.form = this.fb.group({
      fromUserId: new UntypedFormControl('', [Validators.required]),
      emails: this.fb.array([]),
      emailContent: new UntypedFormControl('Microsoft 365 Email Integration Test', [
        Validators.required,
        Validators.maxLength(100)
      ])
    });
    this.addEmail();
  }

  initAccessTypes() {
    for (const type of this.types) {
      this.accessFormats = this.accessFormats.concat(
        this.accessFormats,
        allowedFileTypeFormats[type]
      );
    }
  }

  addEmail() {
    const lessonForm = this.fb.group({
      email: new UntypedFormControl('', [Validators.pattern(EMAIL_PATTERN)]),
      done: new UntypedFormControl(false)
    });
    this.emails.push(lessonForm);
  }

  deleteEmail(emailIdx: number) {
    this.emails.removeAt(emailIdx);
    if (this.emails.value.length === 0) {
      this.addEmail();
    }
  }

  checkEmail(group) {
    if (group.controls.email.value.trim() && group.valid) {
      group.controls.done.setValue(true);
      this.checkEmailsError();
      if (
        !(
          !this.emails.value.find(el => el.email.trim() === '') &&
          !!(this.emails.value as any).find(el => el.done === false)
        )
      ) {
        this.addEmail();
      }
    }
  }

  checkEmailsError() {
    this.hideEmailsErrorMsg = !!(this.emails.value as any).find(el => el.done === true);
    return this.hideEmailsErrorMsg;
  }

  saveTestEmail() {
    this.submitted = true;
    this.checkEmailsError();
    if (!this.form.valid || !this.hideEmailsErrorMsg) {
      return;
    }
    const payload = this.form.value;
    payload.emails = payload.emails.filter(el => el.done);
    payload['file'] = {
      file: this.draggedFile,
      osType: 'crm_emailprovider_integration_test'
    };
    this.dialogRef.close(this.form.value);
  }

  onFileDropped(fileList: File[]): void {
    if (fileList.length > 1) {
      this.toastr.warning('You cannot upload more than one file');
      return;
    }

    if (this.draggedFile) {
      this.draggedFile = fileList[0];
      this.toastr.success('A new file uploaded successfully');
      return;
    }

    const file = fileList[0];
    if (!this.accessFormats.find(el => el === file.type)) {
      const textTypes =
        this.types.length === 1
          ? this.types[0].toUpperCase()
          : this.types.map((el, i) => {
              return i === this.types.length - 1 ? 'and ' + el.toUpperCase() : el.toUpperCase();
            });
      this.toastr.warning(`Files of type ${textTypes} can be uploaded`);
      return;
    }

    this.draggedFile = file;
  }
}
