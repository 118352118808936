import { Component, inject, Inject, Injector } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { UserService } from '@pinnakl/core/data-providers';
import { ENVIRONMENT_SERVICE, EnvironmentService } from '@pinnakl/core/environment';
import { CrmOptionsStore } from '@pinnakl/crm/data-access';
import { CrmClientConfig, CrmEmailClientConfig } from '@pinnakl/crm/domain';
import { AppNames, User } from '@pinnakl/shared/types';
import { isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { PinnaklSpinnerService } from '@pinnakl/shared/util-providers';
import { VdrUsersFacadeService } from '@pinnakl/vdr/data-access';
import { VdrUser } from '@pinnakl/vdr/domain';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  merge,
  Observable,
  of,
  switchMap,
  tap
} from 'rxjs';

declare const require: any;
const packageJson = require('../../../../../package.json');
const crmOptionsStub = {
  users: [],
  clientConfig: null,
  emailClientConfig: null
};

export enum SettingsTabs {
  accountSettings = 'accountSettings',
  loginSecurity = 'loginSecurity',
  notifications = 'notifications',
  accessControl = 'accessControl',
  changePassword = 'changePassword',
  CRMSettings = 'CRMSettings',
  customAttributes = 'customAttributes',
  emailCampaignConfig = 'emailCampaignConfig',
  vdrSettings = 'vdrSettings',
  WatermarkSettings = 'watermarkSettings'
}

interface Vm {
  user: User;
  vdrUser: VdrUser;
  users: User[];
  clientConfig: CrmClientConfig | null;
  emailClientConfig: CrmEmailClientConfig | null;
  projectName: AppNames;
}

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  private readonly crmOptionsStore = inject(CrmOptionsStore);
  private readonly injector = inject(Injector);
  readonly settingsTabs = SettingsTabs;
  readonly version: string;
  vm$: Observable<Vm>;

  userUpdate$ = new BehaviorSubject<User | null>(null);
  vdrUserUpdate$ = new BehaviorSubject<VdrUser | null>(null);
  currentTab = this.settingsTabs.accountSettings;
  appName: string;
  isInvestorPortal: boolean;
  isPlatformWeb: boolean;

  get ProjectNames() {
    return AppNames;
  }

  constructor(
    private readonly userService: UserService,
    private readonly vdrUsersFacade: VdrUsersFacadeService,
    private readonly pinnaklSpinner: PinnaklSpinnerService,
    @Inject(ENVIRONMENT_SERVICE) private readonly envService: EnvironmentService
  ) {
    this.version = packageJson.version;
    this.appName = this.envService.get('appName');
    this.isInvestorPortal = this.appName === AppNames.INVESTOR_PORTAL;
    this.isPlatformWeb = this.appName === AppNames.PLATFORM_WEB;
    const { id } = this.userService.getUser();
    this.pinnaklSpinner.spin();
    const crmOptions$ = this.isPlatformWeb
      ? of(crmOptionsStub)
      : toObservable(this.crmOptionsStore.options, { injector: this.injector }).pipe(
          filter(isNeitherNullNorUndefined)
        );
    this.vm$ = combineLatest([this.getUser$(id), this.getVdrUser$(id), crmOptions$]).pipe(
      map(([user, vdrUser, { users, clientConfig, emailClientConfig }]) => ({
        user,
        vdrUser,
        users: users.filter(u => u.active),
        emailClientConfig,
        clientConfig,
        projectName: this.appName as AppNames
      })),
      tap(() => this.pinnaklSpinner.stop())
    );
  }

  getUser$(id: number): Observable<User | null> {
    return this.isInvestorPortal
      ? of(null)
      : this.userUpdate$.pipe(switchMap(() => this.userService.getFullUserData(id)));
  }

  getVdrUser$(id: number): Observable<VdrUser | null> {
    return this.isInvestorPortal
      ? merge(this.vdrUsersFacade.getVdrPortalUserById(id), this.vdrUserUpdate$)
      : of(null);
  }

  switchTab(selectedTab: SettingsTabs): void {
    this.currentTab = selectedTab;
  }

  updateVdrUser(vdrUser: VdrUser): void {
    this.vdrUserUpdate$.next(vdrUser);
  }

  updateUser(user: User): void {
    this.userUpdate$.next(user);
  }
}
