<prime-simple-yes-no-modal>
  <ng-template #header>
    <div class="w-full flex align-items-center justify-content-between pt-4 px-4">
      <div class="text-2xl font-medium">{{ config?.data?.header ?? '' }}</div>
      <div
        (click)="close()"
        class="flex align-items-center justify-content-center w-2rem h-2rem cursor-pointer hover:bg-black-alpha-10 border-circle transition-all transition-duration-300"
      >
        <i class="pi pi-times font-bold"></i>
      </div>
    </div>
  </ng-template>
  <ng-template #content>
    <div
      [innerHTML]="config?.data?.content ?? ''"
      class="p-4"
    ></div>
  </ng-template>
  <ng-template #footer>
    <div class="pb-4 px-4 gap-2 flex align-items-center justify-content-end">
      <prime-button
        (onClick)="close()"
        [text]="true"
        label="Cancel"
        severity="secondary"
      ></prime-button>
      <prime-button
        (onClick)="delete()"
        [autofocus]="true"
        label="Delete"
      ></prime-button>
    </div>
  </ng-template>
</prime-simple-yes-no-modal>
