<div class="w-full h-full flex flex-column gap-5">
  <div>
    <h3 class="text-lg font-semibold">Organizations</h3>
    <custom-attributes-feature
      [feature]="CustomAttributesFeature.Organization"
    ></custom-attributes-feature>
  </div>

  <div>
    <h3 class="text-lg font-semibold">Contacts</h3>
    <custom-attributes-feature
      [feature]="CustomAttributesFeature.Contact"
    ></custom-attributes-feature>
  </div>
</div>
