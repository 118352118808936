<div
  *ngIf="customAttribute"
  class="attribute-row relative grid grid-nogutter py-2 px-0_75rem border-1 border-bluegray-200 mb-2 border-round-xl hover:bg-bluegray-50 hover:border-bluegray-500 transition-duration-200"
>
  <div
    class="{{
      withMandatoryColumn ? 'col-4' : 'col-5'
    }} flex align-items-center  justify-content-start text-color"
  >
    {{ customAttribute.name }}
    @if (customAttribute?.timeseries) {
      <div
        class="bg-bluegray-100 border-round-xs white-space-nowrap text-xs capitalize p-1 ml-2 line-height-1"
      >
        Time Series
      </div>
    }
  </div>
  <div class="{{ withMandatoryColumn ? 'col-4' : 'col-5' }} pl-3 flex align-items-center">
    <div
      class="bg-gray-100 h-1_25rem px-2 chip-radius text-color flex align-items-center border-round-sm"
    >
      <i
        [inlineSVG]="'sprites.svg#' + iconsMap[customAttribute.type]"
        class="mr-1 w-0_75rem h-0_75rem line-height-0_75rem flex"
      ></i>
      <span class="line-height-1rem">{{ customAttribute.type }}</span>
    </div>
  </div>
  @if (withMandatoryColumn) {
    <div class="col-2 pl-3 flex align-items-center">
      <div class="text-color flex align-items-center">
        @if (customAttribute.isMandatory) {
          <i
            [style.color]="'#00c94a'"
            class="icon-pinnakl-section-done"
          ></i>
        } @else {
          <i
            class="icon-pinnakl-close"
            [style.color]="'#f52b46'"
          ></i>
        }
      </div>
    </div>
  }
  <div
    class="col-2 action-buttons align-items-center justify-content-end gap-3 absolute right-0 pr-3 transition-all transition-duration-200"
  >
    <div
      (click)="edited.emit(customAttribute)"
      class="w-1_50rem h-1_50rem align-items-center border-circle cursor-pointer flex hover:bg-gray-200 justify-content-center text-color transition-all transition-duration-200"
    >
      <i class="pi pi-pencil text-sm"></i>
    </div>
    <div
      (click)="deleted.emit(customAttribute.id)"
      class="w-1_50rem h-1_50rem align-items-center border-circle cursor-pointer flex hover:bg-gray-200 justify-content-center text-color transition-all transition-duration-200"
    >
      <i class="pi pi-trash text-sm"></i>
    </div>
  </div>
</div>
