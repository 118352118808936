export enum CustomAttributeTypeEnum {
  List = 'List',
  Date = 'Date',
  Checkbox = 'Checkbox',
  Text = 'Text',
  Number = 'Number',
  Url = 'Url',
  Longtext = 'LongText'
}

export enum CustomAttributeFeature {
  Organization = 'organization',
  Contact = 'contact',
  Security = 'security'
}

export interface CustomAttribute {
  id: number;
  name: string;
  type: CustomAttributeTypeEnum;
  feature: string;
  isMandatory: boolean;
  viewOrder: any;
  listOptions?: CustomAttributeListOption[];
  timeseries?: boolean;
}

export interface CustomAttributeListOption {
  value: string;
  viewOrder: number;
}

export interface CustomAttributeExtended extends CustomAttribute {
  values: SecurityCustomAttributeValue[];
}

export interface SecurityCustomAttributeValue {
  value: string | null;
  startDate?: string | null;
  endDate?: string | null;
}

export interface SecurityCustomAttribute {
  id: number;
  customAttributeId: number;
  values: SecurityCustomAttributeValue[];
}
