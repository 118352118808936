import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { WatermarkSettings } from '@pinnakl/shared/crm-settings/domain';
import { withDevtools } from '@pinnakl/shared/signal-store';
import { CrmTargetFundsSummaryApiService } from './watermark-settings-api.service';

type SharedWatermarkSettingsState = {
  watermarkSettingsLoading: boolean;
  watermarkSettingsLoaded: boolean;
  watermarkSettingsUpdating: boolean;
  watermarkSettings: WatermarkSettings | null;
  watermarkFontsLoading: boolean;
  watermarkFontsLoaded: boolean;
  watermarkFonts: string[] | null;
};

const initialState: SharedWatermarkSettingsState = {
  watermarkSettingsLoading: false,
  watermarkSettingsLoaded: false,
  watermarkSettingsUpdating: false,
  watermarkSettings: null,
  watermarkFontsLoading: false,
  watermarkFontsLoaded: false,
  watermarkFonts: null
};

const featureKey = 'crmWatermarkSettings';
export const CrmWatermarkSettingsStore = signalStore(
  { providedIn: 'root' },
  withDevtools(featureKey),
  withState(initialState),
  withMethods((store, api = inject(CrmTargetFundsSummaryApiService)) => ({
    loadWatermarkSettings: async (): Promise<void> => {
      try {
        patchState(store, { watermarkSettingsLoading: true });
        const watermarkSettings = await api.loadWatermarkSettings();
        patchState(store, {
          watermarkSettings: watermarkSettings ?? null,
          watermarkSettingsLoading: false,
          watermarkSettingsLoaded: true
        });
      } finally {
        patchState(store, { watermarkSettingsLoading: false, watermarkSettingsLoaded: true });
      }
    },
    updateWatermarkSettings: async (body: WatermarkSettings): Promise<void> => {
      try {
        patchState(store, { watermarkSettingsUpdating: true });
        const watermarkSettings = await api.updateWatermarkSettings(body);
        patchState(store, {
          watermarkSettings,
          watermarkSettingsUpdating: true
        });
      } finally {
        patchState(store, { watermarkSettingsUpdating: false });
      }
    },
    loadWatermarkFonts: async (): Promise<void> => {
      try {
        const fonts = store.watermarkFonts();
        if (fonts && fonts.length > 0) return;
        patchState(store, { watermarkFontsLoading: true });
        const watermarkFonts = await api.loadWatermarkFonts();
        patchState(store, {
          watermarkFonts,
          watermarkFontsLoading: false,
          watermarkFontsLoaded: true
        });
      } finally {
        patchState(store, { watermarkFontsLoading: false, watermarkFontsLoaded: true });
      }
    }
  }))
);
