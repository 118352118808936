import { signalStore, type } from '@ngrx/signals';
import { withEntities } from '@ngrx/signals/entities';
import {
  CustomAttribute,
  CustomAttributeFeature,
  CustomAttributeFilter
} from '@pinnakl/shared/custom-attributes/domain';
import { withCallState, withDataService, withDevtools } from '@pinnakl/shared/signal-store';
import { CommonCustomAttributesApiService } from './common-custom-attributes-api.service';

// use camelCase for store key
const customAttributesStoreKey = 'commonContactCustomAttributes';
export const CommonContactCustomAttributesStore = signalStore(
  { providedIn: 'root' },
  withDevtools(customAttributesStoreKey),
  withCallState(),
  withEntities({
    entity: type<CustomAttribute>()
  }),
  withDataService<CustomAttribute, CustomAttributeFilter, CommonCustomAttributesApiService>({
    dataServiceType: CommonCustomAttributesApiService,
    filter: {
      feature: CustomAttributeFeature.Contact
    }
  })
);
