<h3 class="text-lg font-semibold">Rotation</h3>
@if (watermarkSettingsLoading()) {
  <p-skeleton
    class="flex w-full h-full"
    width="100%"
    height="24rem"
  ></p-skeleton>
} @else {
  <div
    class="grid w-full"
    [formGroup]="rotationForm"
  >
    <!--  Rotation Degree-->
    <prime-input-container class="col-6 block">
      <ng-template #labelContent>
        <prime-label-content
          [formControlName]="FormControlNames.RotationDegree"
          label="Rotation Degree"
        ></prime-label-content>
      </ng-template>
      <ng-template #inputContent>
        <prime-number-input
          class="w-full"
          [showButtons]="true"
          [min]="-180"
          [max]="180"
          [maxFractionDigits]="0"
          [formControlName]="FormControlNames.RotationDegree"
        >
        </prime-number-input>
      </ng-template>
      <ng-template #validationContent>
        <prime-input-validation
          [formControlName]="FormControlNames.RotationDegree"
        ></prime-input-validation>
      </ng-template>
    </prime-input-container>
    <!--  Opacity-->
    <prime-input-container class="col-6 block">
      <ng-template #labelContent>
        <prime-label-content
          [formControlName]="FormControlNames.Opacity"
          label="Opacity"
        ></prime-label-content>
      </ng-template>
      <ng-template #inputContent>
        <prime-number-input
          class="w-full"
          [min]="20"
          [max]="100"
          [maxFractionDigits]="0"
          [showButtons]="true"
          [formControlName]="FormControlNames.Opacity"
        >
        </prime-number-input>
      </ng-template>
      <ng-template #validationContent>
        <prime-input-validation
          [formControlName]="FormControlNames.Opacity"
        ></prime-input-validation>
      </ng-template>
    </prime-input-container>
    <!--  Vertical Distance-->
    <prime-input-container class="col-6 block">
      <ng-template #labelContent>
        <prime-label-content
          [formControlName]="FormControlNames.VerticalDistance"
          label="Vertical Distance (Points. 1 Point = 1/72 inch)"
        ></prime-label-content>
      </ng-template>
      <ng-template #inputContent>
        <prime-number-input
          class="w-full"
          [min]="0.0"
          [max]="1000"
          [step]="0.1"
          [maxFractionDigits]="1"
          [showButtons]="true"
          [formControlName]="FormControlNames.VerticalDistance"
        >
        </prime-number-input>
      </ng-template>
      <ng-template #validationContent>
        <prime-input-validation
          [formControlName]="FormControlNames.VerticalDistance"
        ></prime-input-validation>
      </ng-template>
    </prime-input-container>
    <!--  Vertical Distance Reference-->
    <prime-input-container class="col-6 block">
      <ng-template #labelContent>
        <prime-label-content
          [formControlName]="FormControlNames.VerticalDistanceReference"
          label="Vertical Distance Reference"
        ></prime-label-content>
      </ng-template>
      <ng-template #inputContent>
        <prime-single-dropdown
          styleClass="w-full"
          [options]="verticalDistanceReferenceOptions"
          [formControlName]="FormControlNames.VerticalDistanceReference"
        >
        </prime-single-dropdown>
      </ng-template>
      <ng-template #validationContent>
        <prime-input-validation
          [formControlName]="FormControlNames.VerticalDistanceReference"
        ></prime-input-validation>
      </ng-template>
    </prime-input-container>
    <!--  Horizontal Distance-->
    <prime-input-container class="col-6 block">
      <ng-template #labelContent>
        <prime-label-content
          [formControlName]="FormControlNames.HorizontalDistance"
          label="Horizontal Distance (Points. 1 Point = 1/72 inch)"
        ></prime-label-content>
      </ng-template>
      <ng-template #inputContent>
        <prime-number-input
          class="w-full"
          [min]="0.0"
          [max]="1000"
          [step]="0.1"
          [maxFractionDigits]="1"
          [showButtons]="true"
          [formControlName]="FormControlNames.HorizontalDistance"
        >
        </prime-number-input>
      </ng-template>
      <ng-template #validationContent>
        <prime-input-validation
          [formControlName]="FormControlNames.HorizontalDistance"
        ></prime-input-validation>
      </ng-template>
    </prime-input-container>
    <!--  Horizontal Distance Reference-->
    <prime-input-container class="col-6 block">
      <ng-template #labelContent>
        <prime-label-content
          [formControlName]="FormControlNames.HorizontalDistanceReference"
          label="Horizontal Distance Reference"
        ></prime-label-content>
      </ng-template>
      <ng-template #inputContent>
        <prime-single-dropdown
          styleClass="w-full"
          [options]="horizontalDistanceReferenceOptions"
          [formControlName]="FormControlNames.HorizontalDistanceReference"
        >
        </prime-single-dropdown>
      </ng-template>
      <ng-template #validationContent>
        <prime-input-validation
          [formControlName]="FormControlNames.HorizontalDistanceReference"
        ></prime-input-validation>
      </ng-template>
    </prime-input-container>
    <!--  Font-->
    <prime-input-container class="col-6 block">
      <ng-template #labelContent>
        <prime-label-content
          [formControlName]="FormControlNames.Font"
          label="Font"
        ></prime-label-content>
      </ng-template>
      <ng-template #inputContent>
        <prime-single-dropdown
          styleClass="w-full"
          [options]="watermarkFonts() ?? []"
          [formControlName]="FormControlNames.Font"
        >
        </prime-single-dropdown>
      </ng-template>
      <ng-template #validationContent>
        <prime-input-validation [formControlName]="FormControlNames.Font"></prime-input-validation>
      </ng-template>
    </prime-input-container>
    <!--  Font Size-->
    <prime-input-container class="col-6 block">
      <ng-template #labelContent>
        <prime-label-content
          [formControlName]="FormControlNames.FontSize"
          label="Font Size"
        ></prime-label-content>
      </ng-template>
      <ng-template #inputContent>
        <prime-single-dropdown
          styleClass="w-full"
          [options]="watermarkFontsSizes"
          [formControlName]="FormControlNames.FontSize"
        >
        </prime-single-dropdown>
      </ng-template>
      <ng-template #validationContent>
        <prime-input-validation
          [formControlName]="FormControlNames.FontSize"
        ></prime-input-validation>
      </ng-template>
    </prime-input-container>
    <div class="col-12 flex flex-row flex-nowrap justify-content-end pt">
      <prime-button
        (onClick)="submit()"
        [disabled]="!rotationForm.valid || !rotationForm.dirty"
        label="Save"
        class="pt-3"
        severity="primary"
        styleClass="font-semibold"
      ></prime-button>
    </div>
  </div>
}
