import { FormControl, FormGroup, Validators } from '@angular/forms';

export type VerticalDistanceReference = 'Top' | 'Center' | 'Bottom';
export type HorizontalDistanceReference = 'Left' | 'Center' | 'Right';

export enum WatermarkSettingsRotationFormControls {
  Opacity = 'opacity',
  VerticalDistanceReference = 'verticalDistanceReference',
  VerticalDistance = 'verticalDistance',
  HorizontalDistanceReference = 'horizontalDistanceReference',
  HorizontalDistance = 'horizontalDistance',
  RotationDegree = 'rotationDegree',
  Font = 'font',
  FontSize = 'fontSize'
}

export type WatermarkSettingsForm = FormGroup<{
  [WatermarkSettingsRotationFormControls.Opacity]: FormControl<number | null>;
  [WatermarkSettingsRotationFormControls.VerticalDistanceReference]: FormControl<VerticalDistanceReference | null>;
  [WatermarkSettingsRotationFormControls.VerticalDistance]: FormControl<number | null>;
  [WatermarkSettingsRotationFormControls.HorizontalDistanceReference]: FormControl<HorizontalDistanceReference | null>;
  [WatermarkSettingsRotationFormControls.HorizontalDistance]: FormControl<number | null>;
  [WatermarkSettingsRotationFormControls.RotationDegree]: FormControl<number | null>;
  [WatermarkSettingsRotationFormControls.Font]: FormControl<string | null>;
  [WatermarkSettingsRotationFormControls.FontSize]: FormControl<number | null>;
}>;

export const VerticalDistanceReferenceOptions = [
  { label: 'Top', value: 'Top' },
  { label: 'Center', value: 'Center' },
  { label: 'Bottom', value: 'Bottom' }
];
export const HorizontalDistanceReferenceOptions = [
  { label: 'Left', value: 'Left' },
  { label: 'Center', value: 'Center' },
  { label: 'Right', value: 'Right' }
];

export const buildWatermarkSettingsForm = (): WatermarkSettingsForm => {
  return new FormGroup({
    [WatermarkSettingsRotationFormControls.RotationDegree]: new FormControl<number | null>(null, {
      nonNullable: false,
      validators: [Validators.required, Validators.min(-180), Validators.max(180)]
    }),
    [WatermarkSettingsRotationFormControls.Opacity]: new FormControl<number | null>(null, {
      nonNullable: false,
      validators: [Validators.required, Validators.min(20), Validators.max(100)]
    }),
    [WatermarkSettingsRotationFormControls.VerticalDistance]: new FormControl<number | null>(null, {
      nonNullable: false,
      validators: [Validators.required, Validators.min(0), Validators.max(1000)]
    }),
    [WatermarkSettingsRotationFormControls.VerticalDistanceReference]:
      new FormControl<VerticalDistanceReference | null>(null, {
        nonNullable: false,
        validators: [Validators.required]
      }),
    [WatermarkSettingsRotationFormControls.HorizontalDistance]: new FormControl<number | null>(
      null,
      {
        nonNullable: false,
        validators: [Validators.required, Validators.min(0), Validators.max(1000)]
      }
    ),
    [WatermarkSettingsRotationFormControls.HorizontalDistanceReference]:
      new FormControl<HorizontalDistanceReference | null>(null, {
        nonNullable: false,
        validators: [Validators.required]
      }),
    [WatermarkSettingsRotationFormControls.Font]: new FormControl<string | null>(null, {
      nonNullable: false,
      validators: [Validators.required, Validators.min(5), Validators.max(50)]
    }),
    [WatermarkSettingsRotationFormControls.FontSize]: new FormControl<number | null>(null, {
      nonNullable: false,
      validators: [Validators.required]
    })
  });
};
