<div class="title">
  <span> Test Email </span>
</div>
<div>
  <div class="modal-body padding0">
    <section class="section-padding section-white">
      <form
        [class.ng-submitted]="submitted"
        class="container-fluid row"
      >
        <pinnakl-input
          [dropdownOptions]="{
            viewProperty: 'fullName'
          }"
          [dropdownSource]="users"
          [form]="form"
          [required]="true"
          class="col-12"
          controlName="fromUserId"
          inputClass="pnkl-select-ghost"
          label="FROM"
          labelClass="text-uppercase input-label"
          type="dropdown"
        >
        </pinnakl-input>

        <div
          [formGroup]="form"
          class="row col-7 list-emails"
        >
          <ng-container formArrayName="emails">
            <label class="col-12">TO</label>
            <ng-container *ngFor="let emailForm of emails.controls; let i = index">
              <div
                [formGroup]="$any(emailForm)"
                class="col-12"
              >
                <div class="input-email-container">
                  <input
                    (blur)="checkEmail(emailForm)"
                    (keyup.enter)="checkEmail(emailForm)"
                    *ngIf="!emailForm.get('done').value"
                    formControlName="email"
                    type="text"
                  />
                  <span
                    *ngIf="emailForm.get('done').value"
                    class="done-email"
                  >
                    <span class="value">
                      {{ emailForm.get('email').value }}
                    </span>
                    <span
                      (click)="deleteEmail(i)"
                      class="icon-pinnakl-remove"
                    ></span>
                  </span>
                </div>
              </div>
            </ng-container>
            <div
              *ngIf="!hideEmailsErrorMsg"
              class="color-red text-upper-case"
            >
              Please add at least one valid email
            </div>
          </ng-container>
        </div>

        <pinnakl-input
          [form]="form"
          [showValidation]="true"
          class="col-12"
          controlName="emailContent"
          inputClass="height-8 with-border"
          label="EMAIL"
          type="textArea"
        ></pinnakl-input>
      </form>
      <div
        (fileDropped)="onFileDropped($event)"
        [maxFileSize]="maxFileSize"
        class="upload-file-container"
        pinnaklFileDrop
      >
        <ng-container *ngIf="draggedFile; then fileSelected; else fileNotSelected"></ng-container>

        <ng-template #fileSelected>
          <div class="uploaded-file-container">
            <div>Selected file:</div>
            <div class="uploaded-file">
              <div>{{ draggedFile?.name || draggedFile?.fileName }}</div>
              <i
                (click)="draggedFile = null"
                *ngIf="!selectedFile"
                class="icon-pinnakl-close"
              ></i>
            </div>
          </div>
        </ng-template>
        <ng-template #fileNotSelected>
          <!--           <div class="icon-cloud"></div> -->
          <div class="text">
            <span>Drag & drop to upload file</span>
          </div>
          <div class="text">
            <span>Or</span>
          </div>
          <label
            class="button-container blue b-r-100"
            for="file"
          >
            <span>Browse file</span>
          </label>
          <input
            (change)="onFileDropped($any($event).target.files)"
            [accept]="accessFormats"
            hidden
            id="file"
            type="file"
          />
        </ng-template>
      </div>
    </section>

    <div class="button-controls">
      <button
        (click)="dialogRef.close()"
        class="button-container white"
      >
        <span>Cancel</span>
      </button>
      <button
        (click)="saveTestEmail()"
        class="button-container blue"
        type="submit"
      >
        <span>Send Test Email</span>
      </button>
    </div>
  </div>
</div>
