@if (items.length > 0) {
  <h4 class="uppercase font-semibold font-size-11-px text-color-secondary line-height-1rem my-2">
    List options
  </h4>
}
<div class="list-options-container flex flex-wrap mt-2">
  <div
    (cdkDropListDropped)="drop($event)"
    cdkDropList
    class="list-options-list flex flex-column gap-2 overflow-hidden w-full max-w-full"
  >
    @for (item of items; track item) {
      <div
        cdkDragBoundary=".list-options-container"
        cdkDrag
        class="list-options-box bg-white gap-2 p-2 flex flex-row flex-nowrap line-height-1rem border-1 border-bluegray-300 border-round-lg"
      >
        <i
          cdkDragHandle
          [inlineSVG]="'sprites.svg#reorder'"
          class="h-1rem w-1rem cursor-move flex"
        ></i>
        <div class="flex-grow-1">{{ item }}</div>
        <div
          class="h-1rem w-1rem flex align-items-center justify-content-center cursor-pointer"
          (click)="optionDeleted($index)"
        >
          <i
            [inlineSVG]="'sprites.svg#close'"
            class="h-0_50rem w-0_50rem flex"
          ></i>
        </div>
      </div>
    }
  </div>
</div>
