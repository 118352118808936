<div
  class="flex flex-row align-items-start justify-content-between px-2 py-2 sm:px-4 sm:pb-3 sm:pt-4"
>
  <div class="relative w-full flex flex-row flex-nowrap align-items-center justify-content-between">
    <h3 class="font-semibold text-2xl m-0 line-height-2rem mr-2">
      {{ this.isEdit ? 'Edit custom attribute' : 'New custom attribute' }}
    </h3>
    <prime-button
      (onClick)="close()"
      [text]="true"
      class="absolute close-btn right-0"
      icon="pi pi-times"
      severity="secondary"
    ></prime-button>
  </div>
</div>
<div class="custom-attribute-dialog-body w-full bg-white overflow-y-auto">
  <form
    (ngSubmit)="customAttributeForm.valid && onSubmit()"
    [formGroup]="customAttributeForm"
    class="flex flex-column mx-auto p-4 gap-4"
  >
    <div
      class="custom-attribute-wrapper hidden-scrollbar flex flex-column w-full mx-auto gap-4 overflow-y-auto"
    >
      <prime-input-container>
        <ng-template #labelContent>
          <prime-label-content
            formControlName="type"
            label="Type"
          ></prime-label-content>
        </ng-template>
        <ng-template #inputContent>
          <prime-select-button
            [multiple]="false"
            [options]="modelOptions"
            [isFullWidth]="true"
            class="w-full mb-4"
            formControlName="type"
            variant="separated"
            styleClass="flex-wrap"
          >
            <ng-template
              #itemTemplate
              let-data
            >
              <i
                [inlineSVG]="'sprites.svg#' + iconsMap[data.value]"
                class="w-1rem h-1rem flex mr-2"
              ></i>
              <span class="text-sm line-height-1rem">{{ data.label }}</span>
            </ng-template>
          </prime-select-button>
        </ng-template>
      </prime-input-container>

      @if (withMandatory) {
        <div class="flex flex-row justify-content-start">
          <prime-input-switch
            formControlName="isMandatory"
            label="Mandatory"
            labelPos="right"
          ></prime-input-switch>
        </div>
      }

      @if (withTimeSeries) {
        <div class="flex flex-row align-items-center justify-content-start">
          <prime-input-switch
            formControlName="timeseries"
            label="Time series"
            labelPos="right"
          ></prime-input-switch>
          <i
            class="flex w-1rem h-1rem ml-2"
            inlineSVG="sprites.svg#exclamation-mark"
            pTooltip="Time series field is used to store different values over time. Examples. - S&P and Moody ratings"
          ></i>
        </div>
      }

      <prime-input-container>
        <ng-template #labelContent>
          <prime-label-content
            formControlName="name"
            label="Name"
          ></prime-label-content>
        </ng-template>
        <ng-template #inputContent>
          <prime-text-input
            formControlName="name"
            placeholder="Enter name"
          ></prime-text-input>
        </ng-template>
        <ng-template #validationContent>
          <prime-input-validation
            [customErrors]="{ viewAlreadyExists: 'Item with such name already exists' }"
            class="mt-1"
            formControlName="name"
          ></prime-input-validation>
        </ng-template>
      </prime-input-container>

      @if (typeControl?.value === attributeTypes.List) {
        <div>
          <prime-input-container class="custom-attribute-list-option">
            <ng-template #labelContent>
              <prime-label-content
                formControlName="listOption"
                label="New List Options"
              ></prime-label-content>
            </ng-template>
            <ng-template #inputContent>
              <span class="w-full relative">
                <prime-text-input
                  iconPosition="left"
                  iconClass="pi pi-plus"
                  [inputClasses]="{ 'pr-8': true }"
                  placeholder="Type the option and press Enter"
                  formControlName="listOption"
                  (keydown.enter)="onListOptionKeyDown($event)"
                  (keydown.escape)="onListOptionKeyDown($event)"
                  (focusEvent)="enableOptionValidation()"
                  (blurEvent)="disableOptionValidation()"
                ></prime-text-input>
                <div
                  class="enter-icon absolute flex align-items-center text-sm text-color-secondary line-height-1_25rem"
                >
                  <span>Enter</span>
                  <i
                    class="flex ml-2 w-1rem h-1rem text-bluegray-500"
                    inlineSVG="sprites.svg#enterKey"
                  ></i>
                </div>
              </span>
            </ng-template>
            <ng-template #validationContent>
              <prime-input-validation
                [customErrors]="{ viewAlreadyExists: 'Option with such name already exists' }"
                class="mt-1"
                formControlName="listOption"
              ></prime-input-validation>
            </ng-template>
          </prime-input-container>

          @if (listOptions.hasError('minEntities')) {
            <div class="input-validation-error">At least one option is required</div>
          }
          <custom-attribute-list-options
            [items]="items$ | async"
            (listOptionsChange)="listOptionsChange($event)"
            (listOptionDeleted)="listOptionDeleted($event)"
          ></custom-attribute-list-options>
        </div>
      }
    </div>

    <div class="w-full flex flex-row flex-nowrap align-items-center justify-content-end gap-2">
      <prime-button
        (onClick)="close()"
        [text]="true"
        label="Close"
        severity="secondary"
      ></prime-button>
      <prime-button
        [disabled]="customAttributeForm.invalid"
        [label]="this.isEdit ? 'Save Changes' : 'Add Attribute'"
        type="submit"
      ></prime-button>
    </div>
  </form>
</div>
