<div class="account-settings">
  <h2 class="title">Account settings</h2>
  <form
    (ngSubmit)="accountForm.valid && onSubmit()"
    [formGroup]="accountForm"
  >
    <div>
      <div class="row">
        <div class="account-settings-control">
          <prime-input-container>
            <ng-template #labelContent>
              <prime-label-content
                formControlName="firstName"
                label="First name"
              ></prime-label-content>
            </ng-template>
            <ng-template #inputContent>
              <prime-text-input formControlName="firstName"> </prime-text-input>
            </ng-template>
            <ng-template #validationContent>
              <prime-input-validation
                class="mt-1"
                formControlName="firstName"
              ></prime-input-validation>
            </ng-template>
          </prime-input-container>
        </div>
        <div class="account-settings-control">
          <prime-input-container>
            <ng-template #labelContent>
              <prime-label-content
                formControlName="lastName"
                label="Last name"
              ></prime-label-content>
            </ng-template>
            <ng-template #inputContent>
              <prime-text-input formControlName="lastName"> </prime-text-input>
            </ng-template>
            <ng-template #validationContent>
              <prime-input-validation
                class="mt-1"
                formControlName="lastName"
              ></prime-input-validation>
            </ng-template>
          </prime-input-container>
        </div>
      </div>
      <div class="row">
        <div class="account-settings-control">
          <prime-input-container>
            <ng-template #labelContent>
              <prime-label-content
                formControlName="emailAddress"
                label="Email"
              ></prime-label-content>
            </ng-template>
            <ng-template #inputContent>
              <prime-text-input formControlName="emailAddress"> </prime-text-input>
            </ng-template>
            <ng-template #validationContent>
              <prime-input-validation
                [customErrors]="{
                  pattern: 'Email is not valid.'
                }"
                class="mt-1"
                formControlName="emailAddress"
              ></prime-input-validation>
            </ng-template>
          </prime-input-container>
        </div>
        <div class="account-settings-control">
          <prime-input-container>
            <ng-template #labelContent>
              <prime-label-content
                formControlName="phoneNumber"
                label="Phone number"
              ></prime-label-content>
            </ng-template>
            <ng-template #inputContent>
              <prime-mask-input
                formControlName="phoneNumber"
                mask="+9-999-999-9999"
                styleClass="w-100"
              >
              </prime-mask-input>
            </ng-template>
            <ng-template #validationContent>
              <prime-input-validation
                [customErrors]="{
                  pattern: 'Phone is not valid.'
                }"
                class="mt-1"
                formControlName="phoneNumber"
              ></prime-input-validation>
            </ng-template>
          </prime-input-container>
        </div>
      </div>
    </div>

    <div class="row justify-content-between m-0 p-0">
      <div>
        <button
          #anchor
          (click)="showFormCancelConfirmation()"
          [disabled]="!accountForm.dirty"
          class="btn btn-security btn-cancel"
          type="button"
        >
          CANCEL
        </button>
        <confirm-action
          (onCancelled)="cancelReset()"
          (onConfirmed)="resetForm()"
          [anchor]="anchor"
          [showConfirmation]="cancelConfirmationVisible"
          confirmationMessage="Are you sure you want to reset the form?"
        >
        </confirm-action>
      </div>
      <div>
        <button
          [disabled]="!accountForm.dirty || !accountForm.valid"
          class="btn btn-security btn-save"
          type="submit"
        >
          SAVE
        </button>
      </div>
    </div>
  </form>
</div>
