import { Component, HostBinding } from '@angular/core';
import { CustomAttributeFeature } from '@pinnakl/shared/custom-attributes/domain';
import { CustomAttributesFeatureComponent } from '@pinnakl/shared/custom-attributes/feature';

@Component({
  standalone: true,
  imports: [CustomAttributesFeatureComponent],
  selector: 'custom-attributes-field-list',
  templateUrl: './custom-attributes-field-list.component.html',
  styleUrls: ['./custom-attributes-field-list.component.scss']
})
export class CustomAttributesFieldListComponent {
  @HostBinding('class') hostClasses = 'h-full overflow-auto';
  protected readonly CustomAttributesFeature = CustomAttributeFeature;
}
