import { inject, Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { V4_ENDPOINT } from '@pinnakl/shared/constants';
import { CustomAttribute, CustomAttributeFilter } from '@pinnakl/shared/custom-attributes/domain';
import { DataService } from '@pinnakl/shared/signal-store';
import { getErrorMessage } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { firstValueFrom, Observable, tap } from 'rxjs';

type UpdateMethod = 'post' | 'put';

const BASE_URL = `${V4_ENDPOINT}custom-attributes`;

@Injectable({
  providedIn: 'root'
})
export class CommonCustomAttributesApiService
  implements DataService<CustomAttribute, CustomAttributeFilter>
{
  private readonly toast = inject(PinnaklUIToastMessage);

  constructor(private wsp: WebServiceProvider) {}

  async load(filter: CustomAttributeFilter): Promise<CustomAttribute[]> {
    try {
      return await firstValueFrom(this.find(filter));
    } catch (error) {
      this.toast.warning('Unable to load custom attributes.' + getErrorMessage('', error));
      throw error;
    }
  }

  async loadById(id: number, filter: CustomAttributeFilter): Promise<CustomAttribute> {
    try {
      return await firstValueFrom(this.findById(id, filter));
    } catch (error) {
      this.toast.warning('Unable to load custom attribute.' + getErrorMessage('', error));
      throw error;
    }
  }

  async create(
    data: Partial<CustomAttribute>,
    filter: CustomAttributeFilter
  ): Promise<CustomAttribute> {
    try {
      return await firstValueFrom(this.createEntity(data, filter));
    } catch (error) {
      this.toast.warning('Unable to create custom attribute.' + getErrorMessage('', error));
      throw error;
    }
  }

  async updateById(
    id: number,
    data: Partial<CustomAttribute>,
    filter: CustomAttributeFilter
  ): Promise<CustomAttribute> {
    try {
      return await firstValueFrom(this.updateEntity(id, data, filter, 'put'));
    } catch (error) {
      this.toast.warning(
        `Unable to update ${data?.name ?? 'custom attribute'}.` + getErrorMessage('', error)
      );
      throw error;
    }
  }

  async removeById(
    id: number,
    attribute?: Partial<CustomAttribute>,
    filter?: CustomAttributeFilter
  ): Promise<void> {
    try {
      return await firstValueFrom(this.removeEntity(id, attribute, filter));
    } catch (error) {
      this.toast.warning('Unable to delete custom attribute.' + getErrorMessage('', error));
      throw error;
    }
  }

  public findById(id: number, filter: CustomAttributeFilter): Observable<CustomAttribute> {
    return this.wsp.get<CustomAttribute>({ endpoint: `${BASE_URL}/${filter.feature}/${id}` });
  }

  public createEntity(
    body: Partial<CustomAttribute>,
    filter: CustomAttributeFilter
  ): Observable<CustomAttribute> {
    return this.wsp.post<CustomAttribute>({ endpoint: `${BASE_URL}/${filter.feature}`, body }).pipe(
      tap(() => {
        this.toast.success(`${body?.name ?? 'New'} field has been created`);
      })
    );
  }

  public updateEntity(
    id: number,
    body: Partial<CustomAttribute>,
    filter: CustomAttributeFilter,
    method: UpdateMethod
  ): Observable<CustomAttribute> {
    return this.wsp[method]<CustomAttribute>({
      endpoint: `${BASE_URL}/${filter.feature}/${id}`,
      body
    }).pipe(
      tap(() => {
        this.toast.success(`${body?.name ? body.name + 'field' : 'Field'} has been updated`);
      })
    );
  }

  public removeEntity(
    id: number,
    attribute?: Partial<CustomAttribute>,
    filter?: CustomAttributeFilter
  ): Observable<undefined> {
    return this.wsp.delete<undefined>({ endpoint: `${BASE_URL}/${filter?.feature}/${id}` }).pipe(
      tap(() => {
        this.toast.success(
          `${attribute?.name ? attribute.name + 'field' : 'Field'} has been deleted`
        );
      })
    );
  }

  private find(filter: CustomAttributeFilter): Observable<CustomAttribute[]> {
    return this.wsp.get<CustomAttribute[]>({
      endpoint: `${BASE_URL}/${filter.feature}`
    });
  }
}
