import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { VdrUserWithClientConfig } from '@pinnakl/vdr/domain';
import { DeleteVdrUsers, LoadVdrUsers, SetVdrUsers, UpdateVdrUsers } from './vdr-users.actions';

export interface State extends EntityState<VdrUserWithClientConfig> {
  vdrUsers: VdrUserWithClientConfig[];
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<VdrUserWithClientConfig> =
  createEntityAdapter<VdrUserWithClientConfig>();

export const initialState: State = adapter.getInitialState({
  vdrUsers: [],
  loaded: false,
  loading: false
});

const featureReducer = createReducer(
  initialState,
  on(LoadVdrUsers, state => ({
    ...state,
    loaded: false,
    loading: true
  })),
  on(SetVdrUsers, (state, { vdrUsers }) =>
    adapter.setAll(vdrUsers, {
      ...state,
      loaded: true,
      loading: false
    })
  ),
  on(UpdateVdrUsers, (state, { vdrUsers }) => adapter.upsertMany(vdrUsers, state)),
  on(DeleteVdrUsers, (state, { vdrUserIds }) => adapter.removeMany(vdrUserIds, state))
);

export function reducer(state: State | undefined, action: Action): State {
  return featureReducer(state, action);
}

export const { selectAll } = adapter.getSelectors();

export const selectLoaded = (state: State) => state.loaded;
export const selectLoading = (state: State) => state.loading;
