import { Component, OnInit } from '@angular/core';
import { UserService } from '@pinnakl/core/data-providers';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';

@Component({
  selector: 'crm-settings',
  templateUrl: './crm-settings.component.html',
  styleUrls: ['./crm-settings.component.scss']
})
export class CrmSettingsComponent implements OnInit {
  blockedIps: any[] = [];

  newBlockedIp = '';

  constructor(
    private readonly _crmSettingsService: UserService,
    private readonly _spinner: PinnaklSpinnerService,
    private readonly _toastr: PinnaklUIToastMessage
  ) {}

  ngOnInit(): void {
    this.loadBlockedIps();
  }

  removeEntity(item: any): void {
    this._spinner.spin();
    this._crmSettingsService
      .removeBlockedIps(item.id)
      .then(() => {
        this.loadBlockedIps();
        this._toastr.success(`IP address ${item.ipaddress} deleted`);
      })
      .catch(() => {
        this._spinner.stop();
        this._toastr.error('Error in changing');
      });
  }

  addEntity() {
    if (this.checkValidNewIp()) {
      this._spinner.spin();
      this._crmSettingsService
        .postBlockedIps({ ipaddress: this.newBlockedIp })
        .then(() => {
          this.loadBlockedIps();
          this._toastr.success(`IP address ${this.newBlockedIp} added`);
          this.newBlockedIp = '';
        })
        .catch(() => {
          this._spinner.stop();
          this._toastr.error('Error in changing');
        });
    }
  }

  checkValidNewIp() {
    return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      this.newBlockedIp
    );
  }

  private loadBlockedIps(): void {
    this._spinner.spin();
    this._crmSettingsService.getBlockedIps().then(ips => {
      this.blockedIps = ips;
      this._spinner.stop();
    });
  }
}
