import { Action, createReducer, on } from '@ngrx/store';
import { CrmVdrConfig } from '@pinnakl/vdr/domain';
import { LoadVdrConfig, LoadVdrConfigError, SetVdrConfig } from './vdr-config.actions';

export interface State {
  config: CrmVdrConfig | null;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  config: null,
  error: null,
  loaded: false,
  loading: false
};

const featureReducer = createReducer(
  initialState,
  on(LoadVdrConfig, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(SetVdrConfig, (state, { config }) => ({
    ...state,
    config,
    loaded: true,
    loading: false
  })),
  on(LoadVdrConfigError, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return featureReducer(state, action);
}

export const selectVdrConfig = (state: State) => state.config;
export const selectVdrConfigError = (state: State) => state.error;
export const selectVdrConfigLoading = (state: State) => state.loading;
export const selectVdrConfigLoaded = (state: State) => state.loaded;
