import { CustomAttributeTypeEnum } from '@pinnakl/shared/custom-attributes/domain';

export enum CAFormControlNames {
  Term = 'term',
  Value = 'value',
  CustomAttributeValues = 'customAttributeValues',
  StartDate = 'startDate',
  EndDate = 'endDate',
  MinDate = 'minDate'
}

export const CUSTOM_ATTRIBUTE_DATE_FORMAT = 'YYYY-MM-DD';

export const ICONS_MAP = {
  [CustomAttributeTypeEnum.List]: 'list-type-icon',
  [CustomAttributeTypeEnum.Date]: 'date-type-icon',
  [CustomAttributeTypeEnum.Checkbox]: 'checkbox-type-icon',
  [CustomAttributeTypeEnum.Text]: 'text-type-icon',
  [CustomAttributeTypeEnum.Number]: 'number-type-icon',
  [CustomAttributeTypeEnum.Url]: 'link',
  [CustomAttributeTypeEnum.Longtext]: 'text-type-icon'
};

export const BUTTONS_OPTIONS = [
  {
    label: 'List',
    value: CustomAttributeTypeEnum.List
  },
  {
    label: 'Number',
    value: CustomAttributeTypeEnum.Number
  },
  {
    label: 'Text',
    value: CustomAttributeTypeEnum.Text
  },
  {
    label: 'Date',
    value: CustomAttributeTypeEnum.Date
  },
  {
    label: 'Checkbox',
    value: CustomAttributeTypeEnum.Checkbox
  },
  {
    label: 'URL',
    value: CustomAttributeTypeEnum.Url
  },
  {
    label: 'Longtext',
    value: CustomAttributeTypeEnum.Longtext
  }
];
