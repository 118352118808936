import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@pinnakl/auth/providers';
import { UserService } from '@pinnakl/core/data-providers';
import {
  TwoFactorType,
  TwoFactorTypeConvertMap,
  User,
  UserAuthType,
  UserAuthTypeConvertMap,
  multiFactorTypes,
  requestAuthenticationHours,
  userAuthTypes
} from '@pinnakl/shared/types';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { Observable, distinctUntilChanged } from 'rxjs';
import { map } from 'rxjs/operators';

const securityFormValidator: any = (fg: UntypedFormGroup) => {
  const authType = fg.get('authType')?.value;
  const otpChannel = fg.get('otpChannel')?.value;
  return (authType === UserAuthType.TWO_FACTOR &&
    Object.values(TwoFactorType).includes(otpChannel)) ||
    authType !== UserAuthType.TWO_FACTOR
    ? null
    : { isTwoFactorValid: false };
};

@Component({
  selector: 'login-security',
  templateUrl: './login-security.component.html',
  styleUrls: ['./login-security.component.scss']
})
export class LoginSecurityComponent implements OnChanges {
  private readonly userAuthType = UserAuthType;
  @Output() updateUsers = new EventEmitter<User[]>();
  @Output() cancelModal = new EventEmitter<void>();
  qrCodeSecret$: Observable<string> = this.authService.otpSecret$.asObservable().pipe(
    distinctUntilChanged(),
    map(otpSecret => this.createQrCodeDecryptedValue(otpSecret ?? ''))
  );
  hideQrModalDialog = true;
  users: User[] = [];
  forMultipleUsers: boolean;
  usersWithMissedPhone: User[] = [];
  usersWithMissedEmail: User[] = [];
  usersFullNamesWithMissedPhone = '';
  usersFullNamesWithMissedEmail = '';
  readonly userAuthTypes = userAuthTypes;
  readonly requestAuthenticationHours = requestAuthenticationHours;
  readonly multiFactorTypes = multiFactorTypes;
  cancelConfirmationVisible = false;
  loginSecurityForm: UntypedFormGroup;

  // eslint-disable-next-line
  @Input('users') set setUsers(value: User[]) {
    this.users = value || [];
    this.forMultipleUsers = this.users?.length > 1;
    this.usersWithMissedEmail = this.getUsersWithMissedEmail(this.users);
    this.usersWithMissedPhone = this.getUsersWithMissedPhone(this.users);
    this.resetForm();
  }

  get isMultiFactorSelected(): boolean {
    return this.loginSecurityForm?.get('authType')?.value === this.userAuthType.TWO_FACTOR;
  }

  constructor(
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly spinner: PinnaklSpinnerService,
    private readonly toastr: PinnaklUIToastMessage
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.users) {
      this.resetForm();
    }
  }

  showFormCancelConfirmation(): void {
    this.cancelConfirmationVisible = true;
  }

  cancelReset(): void {
    this.cancelConfirmationVisible = false;
  }

  resetForm(): void {
    this.initLoginSecurityForm();
    this.cancelReset();
  }

  onCancelModal(): void {
    this.cancelModal.emit();
  }

  onSubmit(): void {
    if (this.forMultipleUsers) {
      this.users.forEach(u => {
        const userAuthToSave = {
          id: u?.id.toString(),
          authType: this.loginSecurityForm.get('authType')?.value,
          tokenreauthinterval: this.loginSecurityForm.get('tokenReAuthInterval')?.value.toString()
        };
        this.saveUser({
          ...u,
          ...userAuthToSave
        } as unknown as User);
      });
      this.toastr.success('Successfully changed');
    } else {
      const userAuthToSave = {
        id: this.users[0]?.id.toString(),
        authType: this.loginSecurityForm.get('authType')?.value,
        tokenreauthinterval: this.loginSecurityForm.get('tokenReAuthInterval')?.value.toString()
      };
      this.saveUser({
        ...this.users[0],
        ...userAuthToSave
      } as unknown as User);
    }
  }

  updateQrSecret() {
    this.authService.updateQrSecret(() => this.showQrModal());
  }

  showQrModal(): void {
    this.hideQrModalDialog = false;
  }

  closeQrModal(): void {
    this.hideQrModalDialog = true;
  }

  private initLoginSecurityForm(): void {
    if (this.forMultipleUsers) {
      this.loginSecurityForm = new UntypedFormGroup(
        {
          authType: new UntypedFormControl(null, [Validators.required]),
          otpChannel: new UntypedFormControl(null),
          tokenReAuthInterval: new UntypedFormControl(null, [Validators.required])
        },
        { validators: [securityFormValidator] }
      );
    } else {
      this.loginSecurityForm = new UntypedFormGroup(
        {
          authType: new UntypedFormControl(this.users[0] ? this.users[0]?.authType : null, [
            Validators.required
          ]),
          otpChannel: new UntypedFormControl(
            this.users[0] ? this.getOtpChannelForUser(this.users[0]) : null
          ),
          tokenReAuthInterval: new UntypedFormControl(this.users[0]?.tokenReAuthInterval, [
            Validators.required
          ])
        },
        { validators: [securityFormValidator] }
      );
    }
  }

  // if otpChannel is empty - return the default 2FA type.
  private getOtpChannelForUser(user: User) {
    return user.otpChannel ? user.otpChannel : TwoFactorType.EMAIL;
  }

  private getUsersWithMissedPhone(users: User[]): User[] {
    const result = users.filter(u => !u.phone);
    this.usersFullNamesWithMissedPhone = result.map(x => x.fullName).join(', ');
    return result;
  }

  private getUsersWithMissedEmail(users: User[]): User[] {
    const result = users.filter(u => !u.email);
    this.usersFullNamesWithMissedEmail = result.map(x => x.fullName).join(', ');
    return result;
  }

  private saveUser(userAuthToSave: User): void {
    const otpValue = this.loginSecurityForm.get('otpChannel')?.value;

    if (!this.forMultipleUsers && this.isMultiFactorSelected) {
      userAuthToSave['otpChannel'] = otpValue;
    } else if (this.forMultipleUsers) {
      let is2FAEnabled = false;
      if (otpValue === this.multiFactorTypes.email.value) {
        is2FAEnabled = !this.usersWithMissedEmail.find(u => u.id === userAuthToSave.id)?.id;
      } else if (otpValue === this.multiFactorTypes.mobile.value) {
        is2FAEnabled = !this.usersWithMissedPhone.find(u => u.id === userAuthToSave.id)?.id;
      } else if (otpValue === this.multiFactorTypes.qr.value) {
        is2FAEnabled = true;
      }

      if (is2FAEnabled) {
        userAuthToSave['otpChannel'] = otpValue;
      }
    }
    this.spinner.spin();

    const { id, timezone, authType, otpChannel } = userAuthToSave;
    this.userService
      .putUser({
        id,
        timeZone: timezone,
        authType: UserAuthTypeConvertMap[authType],
        otpChannel: TwoFactorTypeConvertMap[otpChannel]
      })
      .then(() => {
        userAuthToSave?.otpSecret &&
          userAuthToSave.otpChannel === TwoFactorType.QR &&
          this.authService.otpSecret$.next(userAuthToSave.otpSecret);
        const loggedInUser = this.userService.getUser();
        loggedInUser &&
          this.userService.setUser({
            ...loggedInUser,
            otpSecret: userAuthToSave?.otpSecret,
            otpChannel: userAuthToSave?.otpChannel,
            authType: userAuthToSave?.authType
          });
        // this.userService.setUser(user);
        this.updateUsers.emit([userAuthToSave]);
        this.spinner.stop();
        if (!this.forMultipleUsers) {
          this.toastr.success('Successfully changed');
        }
      })
      .catch(e => {
        console.error('Error user update', e);
        this.spinner.stop();
        this.toastr.error('Error in changing');
      });
  }

  private createQrCodeDecryptedValue(secretKey: string, issuer = `Pinnakl`): string {
    const key = secretKey ? secretKey : this.userService.getUser().otpSecret;
    return `otpauth://totp/${issuer}?secret=${key}`;
  }
}
