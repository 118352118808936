import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  Signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CrmOptionsStore } from '@pinnakl/crm/data-access';
import {
  CommonContactCustomAttributesStore,
  CommonOrganizationCustomAttributesStore,
  CommonSecurityCustomAttributesStore
} from '@pinnakl/shared/custom-attributes/data-access';
import {
  CustomAttribute,
  CustomAttributeFeature,
  CustomAttributesFeatureStoreType
} from '@pinnakl/shared/custom-attributes/domain';
import {
  CreateEditCustomAttributeModalComponent,
  CUSTOM_ATTRIBUTES_SHARED_DIALOG_CONFIG,
  CustomAttributesTableComponent,
  DeleteCustomAttributeModalComponent
} from '@pinnakl/shared/custom-attributes/shared';
import { PrimeButtonComponent } from '@pinnakl/shared/ui/prime';
import { DialogService } from 'primeng/dynamicdialog';
import { take } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [PrimeButtonComponent, CustomAttributesTableComponent],
  selector: 'custom-attributes-feature',
  templateUrl: './custom-attributes-feature.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomAttributesFeatureComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly dialogService = inject(DialogService);
  private readonly crmOptionsStore = inject(CrmOptionsStore);
  private readonly orgCustomAttributesStore = inject(CommonOrganizationCustomAttributesStore);
  private readonly contactCustomAttributesStore = inject(CommonContactCustomAttributesStore);
  private readonly securityCustomAttributesStore = inject(CommonSecurityCustomAttributesStore);
  private store!: CustomAttributesFeatureStoreType;
  @Input({ required: true }) feature!: CustomAttributeFeature;
  customAttributes!: Signal<CustomAttribute[]>;
  loading!: Signal<boolean>;
  processing!: Signal<boolean>;

  get withMandatory(): boolean {
    return [CustomAttributeFeature.Organization, CustomAttributeFeature.Contact].includes(
      this.feature
    );
  }

  ngOnInit(): void {
    this.store = this.getFeatureStore(this.feature) as CustomAttributesFeatureStoreType;
    this.customAttributes = this.store.entities;
    this.loading = this.store.loadingMany;
    this.processing = this.store.processing;
    this.store.load({ feature: this.feature });
  }

  getFeatureStore(feature: CustomAttributeFeature) {
    switch (feature) {
      case CustomAttributeFeature.Organization:
        return this.orgCustomAttributesStore;
      case CustomAttributeFeature.Contact:
        return this.contactCustomAttributesStore;
      case CustomAttributeFeature.Security:
        return this.securityCustomAttributesStore;
    }
  }

  createAttribute(): void {
    this.showDialog(null);
  }

  editAttribute(customAttribute: Partial<CustomAttribute>): void {
    this.showDialog(customAttribute);
  }

  deleteAttribute(id: number): void {
    const attribute = this.customAttributes().find(({ id: _id }) => _id === id);
    if (!attribute) return;
    this.dialogService
      .open(DeleteCustomAttributeModalComponent, {
        ...CUSTOM_ATTRIBUTES_SHARED_DIALOG_CONFIG,
        data: {
          header: 'Delete Custom Attribute',
          content: `Are you sure you want to delete <b>${attribute.name}</b>?`
        }
      })
      .onClose.pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(async (proceed: boolean) => {
        if (proceed) {
          await this.store.removeById(id);
          this.updateCrmOptions();
        }
      });
  }

  private showDialog(attribute: Partial<CustomAttribute> | null): void {
    this.dialogService
      .open(CreateEditCustomAttributeModalComponent, {
        ...CUSTOM_ATTRIBUTES_SHARED_DIALOG_CONFIG,
        closeOnEscape: false,
        data: {
          attribute,
          feature: this.feature,
          itemNamesList: [...this.customAttributes().map(({ name }) => name)]
        }
      })
      .onClose.pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(async (customAttribute?: Partial<CustomAttribute>) => {
        if (!customAttribute) return;
        if (attribute?.id) {
          await this.store.updateById(attribute.id, customAttribute);
        } else {
          await this.store.create(customAttribute);
        }
        this.updateCrmOptions();
      });
  }

  private updateCrmOptions(): void {
    this.crmOptionsStore.loadCrmOptions(true);
  }
}
