import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  input,
  Output
} from '@angular/core';
import { LocalSpinnerSimpleModeContainerComponent } from '@pinnakl/shared/base-components';
import { CustomAttribute } from '@pinnakl/shared/custom-attributes/domain';
import { EmptyDataBlockComponent } from '@pinnakl/shared/domain-components';
import { SortByPipe } from '@pinnakl/shared/ui/directives-and-pipes';
import { PrimeButtonComponent } from '@pinnakl/shared/ui/prime';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';
import { SharedModule } from 'primeng/api';
import { SkeletonModule } from 'primeng/skeleton';
import { CustomAttributeRowItemComponent } from '../custom-attribute-row-item/custom-attribute-row-item.component';

@Component({
  standalone: true,
  imports: [
    PrimeButtonComponent,
    InlineSVGModule,
    CustomAttributeRowItemComponent,
    LocalSpinnerSimpleModeContainerComponent,
    SortByPipe,
    EmptyDataBlockComponent,
    SharedModule,
    SkeletonModule
  ],
  selector: 'custom-attributes-table',
  templateUrl: './custom-attributes-table.component.html',
  styleUrls: ['./custom-attributes-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomAttributesTableComponent {
  customAttributes = input.required<CustomAttribute[]>();
  loading = input.required<boolean>();
  processing = input.required<boolean>();
  @Input() withMandatoryColumn = false;
  @Output() deleteEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() createEvent = new EventEmitter();

  createAttribute(): void {
    this.createEvent.emit();
  }

  editAttribute(customAttribute: Partial<CustomAttribute>): void {
    this.editEvent.emit(customAttribute);
  }

  deleteAttribute(id: number): void {
    const attribute = this.customAttributes().find(({ id: _id }) => _id === id);
    if (!attribute) return;
    this.deleteEvent.emit(id);
  }
}
