import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from '@pinnakl/shared/types';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { VdrConfigFacadeService } from '@pinnakl/vdr/data-access';
import { CrmVdrConfig } from '@pinnakl/vdr/domain';

@Component({
  selector: 'vdr-configuration',
  templateUrl: './vdr-configuration.component.html',
  styleUrls: ['./vdr-configuration.component.scss']
})
export class VdrConfigurationComponent implements OnInit {
  private vdrConfig: CrmVdrConfig | null;
  @Input() users: User[];
  public form: UntypedFormGroup;

  constructor(
    private readonly vdrConfigFacade: VdrConfigFacadeService,
    private readonly fb: FormBuilder,
    private readonly toastr: PinnaklUIToastMessage,
    private readonly spinner: PinnaklSpinnerService
  ) {
    this.form = this.fb.group({
      sendFileUploadNotifications: [false],
      accessFundPerformance: [true],
      allowFileDownload: [false],
      allowFileUpload: [false],
      activationEmailUserId: [null]
    });
  }

  ngOnInit(): void {
    this.spinner.spin();
    this.vdrConfigFacade.vdrConfig$.subscribe(config => {
      this.spinner.stop();
      if (config) {
        this.vdrConfig = config;
        this.updateFormWithConfigValue(this.vdrConfig);
      }
    });
  }

  updateFormWithConfigValue(config: CrmVdrConfig): void {
    this.form.patchValue(config);
  }

  submit() {
    const {
      sendFileUploadNotifications,
      accessFundPerformance,
      allowFileDownload,
      allowFileUpload,
      activationEmailUserId
    } = this.form.getRawValue();
    const config = {
      sendFileUploadNotifications,
      accessFundPerformance,
      allowFileDownload,
      allowFileUpload,
      ...(activationEmailUserId ? { activationEmailUserId } : {}),
      ...(this.vdrConfig ? { id: this.vdrConfig.id } : {})
    };
    this.spinner.spin();
    this.saveVdrConfig(config).subscribe(response => {
      this.vdrConfig = response;
      this.vdrConfigFacade.setVdrConfig(response);
      this.updateFormWithConfigValue(this.vdrConfig);
      this.toastr.success('VDR configuration saved');
      this.spinner.stop();
    });
  }

  saveVdrConfig(config: Partial<CrmVdrConfig>) {
    return this.vdrConfig
      ? this.vdrConfigFacade.putCrmVdrConfig(config)
      : this.vdrConfigFacade.postCrmVdrConfig(config);
  }
}
