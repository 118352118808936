import { inject, Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { WatermarkSettings } from '@pinnakl/shared/crm-settings/domain';
import { getErrorMessage } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { firstValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export const BASE_URL = `v4/crm/watermarking`;

@Injectable({
  providedIn: 'root'
})
export class CrmTargetFundsSummaryApiService {
  private readonly toast = inject(PinnaklUIToastMessage);
  private readonly wsp = inject(WebServiceProvider);

  async loadWatermarkSettings(): Promise<WatermarkSettings | undefined> {
    try {
      return await firstValueFrom(this.findWatermarkSettings());
    } catch (error) {
      this.toast.warning('Unable to load watermark settings' + getErrorMessage('', error));
      throw error;
    }
  }

  async updateWatermarkSettings(body: WatermarkSettings): Promise<WatermarkSettings> {
    try {
      return await firstValueFrom(this.putWatermarkSettings(body));
    } catch (error) {
      this.toast.warning('Unable to update watermark settings' + getErrorMessage('', error));
      throw error;
    }
  }

  async loadWatermarkFonts(): Promise<string[]> {
    try {
      return await firstValueFrom(this.findWatermarkFonts());
    } catch (error) {
      this.toast.warning('Unable to load watermark fonts.' + getErrorMessage('', error));
      throw error;
    }
  }

  private findWatermarkSettings(): Observable<WatermarkSettings | undefined> {
    return this.wsp.get<WatermarkSettings | undefined>({
      endpoint: `${BASE_URL}/settings`
    });
  }

  private postWatermarkSettings(body: WatermarkSettings): Observable<WatermarkSettings> {
    return this.wsp
      .post<WatermarkSettings>({
        endpoint: `${BASE_URL}/settings`,
        body
      })
      .pipe(tap(() => this.toast.success('Watermarking settings have been saved')));
  }

  private putWatermarkSettings(body: WatermarkSettings): Observable<WatermarkSettings> {
    return this.wsp
      .put<WatermarkSettings>({
        endpoint: `${BASE_URL}/settings`,
        body
      })
      .pipe(tap(() => this.toast.success('Watermarking settings have been saved')));
  }

  private findWatermarkFonts(): Observable<string[]> {
    return this.wsp.get<string[]>({
      endpoint: `${BASE_URL}/settings/watermark-fonts`
    });
  }
}
