<div
  class="no-content flex flex-column align-items-center justify-content-center w-full h-full p-5"
>
  <div class="gap-3 flex flex-column align-items-center justify-content-center">
    @if (emptyIconTemplate) {
      <ng-container *ngTemplateOutlet="emptyIconTemplate"></ng-container>
    } @else {
      <i
        [inlineSVG]="'sprites.svg#spider-empty'"
        class="w-3_50rem h-3_50rem"
      ></i>
    }
    <div class="text-center">
      @if (title) {
        <h4
          [ngClass]="
            titleStyleClass ? titleStyleClass : 'text-base font-semibold line-height-1_50rem'
          "
          class="mb-1"
        >
          {{ title }}
        </h4>
      }
      @if (description) {
        <p class="text-bluegray-700 text-sm line-height-1_25rem">
          {{ description }}
        </p>
      }
    </div>
    @if (showButton) {
      <div
        class="flex flex-column align-items-center justify-content-center w-full"
        [ngClass]="buttonStyleClass ? buttonStyleClass : 'mt-3'"
      >
        @if (buttonTemplate) {
          <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
        } @else {
          <prime-button
            (onClick)="buttonClicked.emit()"
            styleClass="w-100"
            [label]="buttonLabel"
          ></prime-button>
        }
      </div>
    }
  </div>
</div>
