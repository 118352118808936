/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { EventEmitter, Directive, Input, Output, NgModule, Injectable, Component } from '@angular/core';
import { Draggable } from '@progress/kendo-draggable';
import { CommonModule } from '@angular/common';
import { auditTime } from 'rxjs/operators';
import { merge, fromEvent, from } from 'rxjs';

/**
 * @hidden
 */
const isDocumentAvailable = () => typeof document !== 'undefined';

/**
 * @hidden
 */
const isChanged = (propertyName, changes, skipFirstChange = true) => typeof changes[propertyName] !== 'undefined' && (!changes[propertyName].isFirstChange() || !skipFirstChange) && changes[propertyName].previousValue !== changes[propertyName].currentValue;

/**
 * @hidden
 */
const anyChanged = (propertyNames, changes, skipFirstChange = true) => propertyNames.some(name => isChanged(name, changes, skipFirstChange));

/**
 * @hidden
 */
const hasObservers = emitter => emitter && emitter.observers.length > 0;

/**
 * @hidden
 */
const guid = () => {
  let id = "";
  for (let i = 0; i < 32; i++) {
    const random = Math.random() * 16 | 0; // eslint-disable-line no-bitwise
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      id += "-";
    }
    // eslint-disable-next-line no-bitwise
    id += (i === 12 ? 4 : i === 16 ? random & 3 | 8 : random).toString(16);
  }
  return id;
};
const canCreateElement = () => isDocumentAvailable() && document.createElement;
/**
 * @hidden
 */
function getScrollbarWidth() {
  let scrollbarWidth = 0;
  if (canCreateElement()) {
    const div = document.createElement('div');
    div.style.cssText = 'overflow:scroll;overflow-x:hidden;zoom:1;clear:both;display:block';
    div.innerHTML = '&nbsp;';
    document.body.appendChild(div);
    scrollbarWidth = div.offsetWidth - div.scrollWidth;
    document.body.removeChild(div);
  }
  return scrollbarWidth;
}
class DraggableDirective {
  constructor(element, ngZone) {
    this.element = element;
    this.ngZone = ngZone;
    this.enableDrag = true;
    this.kendoPress = new EventEmitter();
    this.kendoDrag = new EventEmitter();
    this.kendoRelease = new EventEmitter();
  }
  ngOnInit() {
    this.toggleDraggable();
  }
  ngOnChanges(changes) {
    if (isChanged('enableDrag', changes)) {
      this.toggleDraggable();
    }
  }
  ngOnDestroy() {
    this.destroyDraggable();
  }
  toggleDraggable() {
    if (isDocumentAvailable()) {
      this.destroyDraggable();
      if (this.enableDrag) {
        this.draggable = new Draggable({
          drag: e => this.kendoDrag.next(e),
          press: e => this.kendoPress.next(e),
          release: e => this.kendoRelease.next(e)
        });
        this.ngZone.runOutsideAngular(() => this.draggable.bindTo(this.element.nativeElement));
      }
    }
  }
  destroyDraggable() {
    if (this.draggable) {
      this.draggable.destroy();
      this.draggable = null;
    }
  }
}
DraggableDirective.ɵfac = function DraggableDirective_Factory(t) {
  return new (t || DraggableDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
DraggableDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: DraggableDirective,
  selectors: [["", "kendoDraggable", ""]],
  inputs: {
    enableDrag: "enableDrag"
  },
  outputs: {
    kendoPress: "kendoPress",
    kendoDrag: "kendoDrag",
    kendoRelease: "kendoRelease"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DraggableDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoDraggable]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, {
    enableDrag: [{
      type: Input
    }],
    kendoPress: [{
      type: Output
    }],
    kendoDrag: [{
      type: Output
    }],
    kendoRelease: [{
      type: Output
    }]
  });
})();

/**
 * @hidden
 */
class DraggableModule {}
DraggableModule.ɵfac = function DraggableModule_Factory(t) {
  return new (t || DraggableModule)();
};
DraggableModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DraggableModule
});
DraggableModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DraggableModule, [{
    type: NgModule,
    args: [{
      declarations: [DraggableDirective],
      exports: [DraggableDirective],
      imports: [CommonModule]
    }]
  }], null, null);
})();
const closestInScope = (node, predicate, scope) => {
  while (node && node !== scope && !predicate(node)) {
    node = node.parentNode;
  }
  if (node !== scope) {
    return node;
  }
};
const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};
const contains = (parent, node, matchSelf = false) => {
  const outside = !closest(node, child => child === parent);
  if (outside) {
    return false;
  }
  const el = closest(node, child => child === node);
  return el && (matchSelf || el !== parent);
};
const findElement = (node, predicate, matchSelf = true) => {
  if (!node) {
    return;
  }
  if (matchSelf && predicate(node)) {
    return node;
  }
  node = node.firstChild;
  while (node) {
    if (node.nodeType === 1) {
      const element = findElement(node, predicate);
      if (element) {
        return element;
      }
    }
    node = node.nextSibling;
  }
};
const focusableRegex = /^(?:a|input|select|option|textarea|button|object)$/i;
const isFocusable = element => {
  if (!element.tagName) {
    return false;
  }
  const tagName = element.tagName.toLowerCase();
  const hasTabIndex = Boolean(element.getAttribute('tabIndex'));
  const focusable = !element.disabled && focusableRegex.test(tagName);
  return focusable || hasTabIndex;
};
const isVisible = element => {
  const rect = element.getBoundingClientRect();
  const hasSize = rect.width > 0 && rect.height > 0;
  const hasPosition = rect.x !== 0 && rect.y !== 0;
  // Elements can have zero size due to styling, but they will still count as visible.
  // For example, the selection checkbox has no size, but is made visible through styling.
  return (hasSize || hasPosition) && window.getComputedStyle(element).visibility !== 'hidden';
};
const isFocusableWithTabKey = (element, checkVisibility = true) => {
  if (!isFocusable(element)) {
    return false;
  }
  const tabIndex = element.getAttribute('tabIndex');
  const visible = !checkVisibility || isVisible(element);
  return visible && tabIndex !== '-1';
};
const findFocusableChild = (element, checkVisibility = true) => {
  return findElement(element, node => isFocusableWithTabKey(node, checkVisibility), false);
};
const findFocusable = (element, checkVisibility = true) => {
  return findElement(element, node => isFocusableWithTabKey(node, checkVisibility));
};
const toClassList = classNames => String(classNames).trim().split(' ');
const hasClasses = (element, classNames) => {
  const namesList = toClassList(classNames);
  return Boolean(toClassList(element.className).find(className => namesList.indexOf(className) >= 0));
};
const matchesClasses = classNames => element => hasClasses(element, classNames);
const NODE_NAME_PREDICATES = {};
const matchesNodeName = nodeName => {
  if (!NODE_NAME_PREDICATES[nodeName]) {
    NODE_NAME_PREDICATES[nodeName] = element => String(element.nodeName).toLowerCase() === nodeName.toLowerCase();
  }
  return NODE_NAME_PREDICATES[nodeName];
};

/**
 * Normalizes a scroll position value in RTL mode.
 */
function rtlScrollPosition(position, element, initial) {
  let result = position;
  if (initial < 0) {
    result = -position;
  } else if (initial > 0) {
    result = element.scrollWidth - element.offsetWidth - position;
  }
  return result;
}

/**
 * @hidden
 */
class EventsOutsideAngularDirective {
  constructor(element, ngZone, renderer) {
    this.element = element;
    this.ngZone = ngZone;
    this.renderer = renderer;
    this.events = {};
  }
  ngOnInit() {
    if (!this.element || !this.element.nativeElement) {
      return;
    }
    const events = this.events;
    this.subscriptions = [];
    this.ngZone.runOutsideAngular(() => {
      for (let name in events) {
        if (events.hasOwnProperty(name)) {
          this.subscriptions.push(this.renderer.listen(this.element.nativeElement, name, this.scope ? events[name].bind(this.scope) : events[name]));
        }
      }
    });
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      for (let idx = 0; idx < this.subscriptions.length; idx++) {
        this.subscriptions[idx]();
      }
      this.subscriptions = null;
    }
  }
}
EventsOutsideAngularDirective.ɵfac = function EventsOutsideAngularDirective_Factory(t) {
  return new (t || EventsOutsideAngularDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Renderer2));
};
EventsOutsideAngularDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: EventsOutsideAngularDirective,
  selectors: [["", "kendoEventsOutsideAngular", ""]],
  inputs: {
    events: [i0.ɵɵInputFlags.None, "kendoEventsOutsideAngular", "events"],
    scope: "scope"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EventsOutsideAngularDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoEventsOutsideAngular]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Renderer2
    }];
  }, {
    events: [{
      type: Input,
      args: ['kendoEventsOutsideAngular']
    }],
    scope: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class EventsModule {}
EventsModule.ɵfac = function EventsModule_Factory(t) {
  return new (t || EventsModule)();
};
EventsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: EventsModule
});
EventsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EventsModule, [{
    type: NgModule,
    args: [{
      declarations: [EventsOutsideAngularDirective],
      exports: [EventsOutsideAngularDirective]
    }]
  }], null, null);
})();
class ResizeService {
  constructor(resizeBatchService) {
    this.resizeBatchService = resizeBatchService;
    this.resize = new EventEmitter();
    this.acceptedSize = false;
    this.state = 0 /* Initial */;
  }
  acceptSize(size = this.measure()) {
    this.lastWidth = size.width;
    this.lastHeight = size.height;
    this.acceptedSize = true;
  }
  checkChanges() {
    if (!isDocumentAvailable()) {
      return;
    }
    if (this.state === 0 /* Initial */) {
      this.state = 1 /* Initializing */;
      // batch initial measure
      this.resizeBatchService.schedule(this, this.init);
    }
  }
  destroy() {
    this.resizeBatchService.cancel(this);
  }
  checkSize() {
    if (!this.parentElement) {
      return;
    }
    const {
      width,
      height
    } = this.measure();
    const sameSize = width === this.lastWidth && height === this.lastHeight;
    if (sameSize) {
      return;
    }
    this.lastWidth = width;
    this.lastHeight = height;
    this.acceptedSize = false;
    this.resize.emit();
    return true;
  }
  initSize() {
    const size = this.measure();
    this.lastWidth = size.width;
    this.lastHeight = size.height;
  }
  measure() {
    let width = 0;
    let height = 0;
    if (this.parentElement) {
      height = this.parentElement.offsetHeight;
      width = this.parentElement.offsetWidth;
    }
    return {
      height,
      width
    };
  }
}

// eslint-disable import/no-deprecated
const div = style => {
  const el = document.createElement('div');
  el.style.cssText = style;
  return el;
};
const computedProp = (elem, prop) => getComputedStyle(elem, null).getPropertyValue(prop);
const WRAP_STYLE = 'position: absolute; display: block; left: 0; top: 0; right: 0; bottom: 0; z-index: -1;' + 'overflow: hidden; visibility: hidden;';
const EXPAND_CHILD_STYLE = 'position: absolute; left: 0; top: 0; transition: 0s;';
const SHRINK_CHILD_STYLE = EXPAND_CHILD_STYLE + 'width: 200%; height: 200%;';
class ResizeCompatService extends ResizeService {
  constructor(resizeBatchService, element, ngZone) {
    super(resizeBatchService);
    this.element = element;
    this.ngZone = ngZone;
  }
  checkChanges() {
    if (this.state === 2 /* Initialized */) {
      if (!this.resizeBatchService.isScheduled(this)) {
        this.resizeBatchService.schedule(this, this.checkSize);
      }
      return;
    }
    super.checkChanges();
  }
  destroy() {
    super.destroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.expand) {
      const element = this.element.nativeElement;
      element.removeChild(this.expand);
      element.removeChild(this.shrink);
      this.expand.removeChild(this.expandChild);
      this.expand = this.expandChild = this.shrink = this.element = null;
    }
  }
  checkSize() {
    if (super.checkSize()) {
      this.reset();
      return true;
    }
  }
  init() {
    const parentElement = this.parentElement = this.element.nativeElement.parentElement;
    if (computedProp(parentElement, 'position') === 'static') {
      parentElement.style.position = 'relative';
    }
    this.state = 2 /* Initialized */;
    this.render();
    this.reset();
    this.initSize();
    this.subscribe();
  }
  render() {
    const element = this.element.nativeElement;
    element.style.cssText = WRAP_STYLE;
    element.setAttribute('dir', 'ltr');
    this.expand = div(WRAP_STYLE);
    this.expandChild = div(EXPAND_CHILD_STYLE);
    this.expand.appendChild(this.expandChild);
    element.appendChild(this.expand);
    this.shrink = div(WRAP_STYLE);
    const shrinkChild = div(SHRINK_CHILD_STYLE);
    this.shrink.appendChild(shrinkChild);
    element.appendChild(this.shrink);
  }
  reset() {
    const expandChild = this.expandChild;
    expandChild.style.width = 100000 + 'px';
    expandChild.style.height = 100000 + 'px';
    const expand = this.expand;
    expand.scrollLeft = 100000;
    expand.scrollTop = 100000;
    const shrink = this.shrink;
    shrink.scrollLeft = 100000;
    shrink.scrollTop = 100000;
  }
  subscribe() {
    this.ngZone.runOutsideAngular(() => {
      this.subscription = merge(fromEvent(this.shrink, 'scroll'), fromEvent(this.expand, 'scroll')).subscribe(() => {
        this.checkSize();
      });
    });
  }
}
const HAS_OBSERVER = typeof ResizeObserver !== 'undefined';
/**
 * @hidden
 */
class ResizeObserverService extends ResizeService {
  constructor(resizeBatchService, element, ngZone) {
    super(resizeBatchService);
    this.element = element;
    this.ngZone = ngZone;
  }
  static supported() {
    return HAS_OBSERVER;
  }
  destroy() {
    super.destroy();
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
    this.parentElement = null;
  }
  init() {
    this.parentElement = this.element.nativeElement.parentElement;
    this.initSize();
    this.state = 2 /* Initialized */;
    this.ngZone.runOutsideAngular(() => {
      this.resizeObserver = new ResizeObserver(() => {
        this.checkSize();
      });
      this.resizeObserver.observe(this.parentElement);
    });
  }
}

/**
 * @hidden
 */
class ResizeBatchService {
  constructor(ngZone) {
    this.ngZone = ngZone;
    this.scheduled = [];
    this.resolvedPromise = Promise.resolve(null);
    this.flush = this.flush.bind(this);
  }
  schedule(instance, method) {
    this.scheduled.push({
      instance,
      method
    });
    if (!this.subscription) {
      this.ngZone.runOutsideAngular(() => {
        this.subscription = from(this.resolvedPromise).subscribe(this.flush);
      });
    }
  }
  isScheduled(instance) {
    return Boolean(this.scheduled.find(item => item.instance === instance));
  }
  cancel(instance) {
    const scheduled = this.scheduled;
    const count = scheduled.length;
    for (let idx = 0; idx < count; idx++) {
      if (scheduled[idx].instance === instance) {
        scheduled.splice(idx, 1);
        if (!scheduled.length) {
          this.unsubscribe();
        }
        return;
      }
    }
  }
  ngOnDestroy() {
    this.unsubscribe();
  }
  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
  flush() {
    this.scheduled.forEach(item => {
      item.method.call(item.instance);
    });
    this.scheduled = [];
    this.unsubscribe();
  }
}
ResizeBatchService.ɵfac = function ResizeBatchService_Factory(t) {
  return new (t || ResizeBatchService)(i0.ɵɵinject(i0.NgZone));
};
ResizeBatchService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ResizeBatchService,
  factory: ResizeBatchService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizeBatchService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.NgZone
    }];
  }, null);
})();

/**
 * Emit up to 10 resize events per second by default.
 * Chosen as a compromise between responsiveness and performance.
 */
const DEFAULT_RATE_LIMIT = 10;
/**
 * Resize Sensor Component
 *
 * Triggers a "resize" event whenever the parent DOM element size changes.
 */
class ResizeSensorComponent {
  constructor(resizeBatchService, element, ngZone) {
    /**
     * The maximum number of resize events to emit per second.
     *
     * Defaults to 10.
     */
    this.rateLimit = DEFAULT_RATE_LIMIT;
    /**
     * Fires when the parent DOM element has been resized.
     */
    this.resize = new EventEmitter();
    const serviceType = ResizeObserverService.supported() ? ResizeObserverService : ResizeCompatService;
    this.resizeService = new serviceType(resizeBatchService, element, ngZone);
    const throttleTime = 1000 / (this.rateLimit || DEFAULT_RATE_LIMIT);
    this.subscription = this.resizeService.resize.pipe(auditTime(throttleTime)).subscribe(() => {
      if (!this.resizeService.acceptedSize) {
        this.resize.emit();
      }
    });
  }
  ngAfterViewChecked() {
    this.resizeService.checkChanges();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.resizeService.destroy();
  }
  acceptSize(size) {
    this.resizeService.acceptSize(size);
  }
}
ResizeSensorComponent.ɵfac = function ResizeSensorComponent_Factory(t) {
  return new (t || ResizeSensorComponent)(i0.ɵɵdirectiveInject(ResizeBatchService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
ResizeSensorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ResizeSensorComponent,
  selectors: [["kendo-resize-sensor"]],
  inputs: {
    rateLimit: "rateLimit"
  },
  outputs: {
    resize: "resize"
  },
  decls: 0,
  vars: 0,
  template: function ResizeSensorComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizeSensorComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-resize-sensor',
      template: ''
    }]
  }], function () {
    return [{
      type: ResizeBatchService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, {
    rateLimit: [{
      type: Input
    }],
    resize: [{
      type: Output
    }]
  });
})();
const COMPONENT_DIRECTIVES = [ResizeSensorComponent];
/**
 * Resize Sensor module
 */
class ResizeSensorModule {}
ResizeSensorModule.ɵfac = function ResizeSensorModule_Factory(t) {
  return new (t || ResizeSensorModule)();
};
ResizeSensorModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ResizeSensorModule
});
ResizeSensorModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [ResizeBatchService]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizeSensorModule, [{
    type: NgModule,
    args: [{
      declarations: [COMPONENT_DIRECTIVES],
      exports: [COMPONENT_DIRECTIVES],
      providers: [ResizeBatchService]
    }]
  }], null, null);
})();
class KendoInput {}

/**
 * Enum with key codes.
 */
var Keys;
(function (Keys) {
  Keys[Keys["Alt"] = 18] = "Alt";
  Keys[Keys["ArrowDown"] = 40] = "ArrowDown";
  Keys[Keys["ArrowLeft"] = 37] = "ArrowLeft";
  Keys[Keys["ArrowRight"] = 39] = "ArrowRight";
  Keys[Keys["ArrowUp"] = 38] = "ArrowUp";
  Keys[Keys["Backspace"] = 8] = "Backspace";
  Keys[Keys["Control"] = 17] = "Control";
  Keys[Keys["Delete"] = 46] = "Delete";
  Keys[Keys["Digit0"] = 48] = "Digit0";
  Keys[Keys["Digit1"] = 49] = "Digit1";
  Keys[Keys["Digit2"] = 50] = "Digit2";
  Keys[Keys["Digit3"] = 51] = "Digit3";
  Keys[Keys["Digit4"] = 52] = "Digit4";
  Keys[Keys["Digit5"] = 53] = "Digit5";
  Keys[Keys["Digit6"] = 54] = "Digit6";
  Keys[Keys["Digit7"] = 55] = "Digit7";
  Keys[Keys["Digit8"] = 56] = "Digit8";
  Keys[Keys["Digit9"] = 57] = "Digit9";
  Keys[Keys["End"] = 35] = "End";
  Keys[Keys["Enter"] = 13] = "Enter";
  Keys[Keys["Escape"] = 27] = "Escape";
  Keys[Keys["F1"] = 112] = "F1";
  Keys[Keys["F2"] = 113] = "F2";
  Keys[Keys["F10"] = 121] = "F10";
  Keys[Keys["Home"] = 36] = "Home";
  Keys[Keys["Insert"] = 45] = "Insert";
  Keys[Keys["KeyA"] = 65] = "KeyA";
  Keys[Keys["KeyB"] = 66] = "KeyB";
  Keys[Keys["KeyC"] = 67] = "KeyC";
  Keys[Keys["KeyD"] = 68] = "KeyD";
  Keys[Keys["KeyE"] = 69] = "KeyE";
  Keys[Keys["KeyF"] = 70] = "KeyF";
  Keys[Keys["KeyG"] = 71] = "KeyG";
  Keys[Keys["KeyH"] = 72] = "KeyH";
  Keys[Keys["KeyI"] = 73] = "KeyI";
  Keys[Keys["KeyJ"] = 74] = "KeyJ";
  Keys[Keys["KeyK"] = 75] = "KeyK";
  Keys[Keys["KeyL"] = 76] = "KeyL";
  Keys[Keys["KeyM"] = 77] = "KeyM";
  Keys[Keys["KeyN"] = 78] = "KeyN";
  Keys[Keys["KeyO"] = 79] = "KeyO";
  Keys[Keys["KeyP"] = 80] = "KeyP";
  Keys[Keys["KeyQ"] = 81] = "KeyQ";
  Keys[Keys["KeyR"] = 82] = "KeyR";
  Keys[Keys["KeyS"] = 83] = "KeyS";
  Keys[Keys["KeyT"] = 84] = "KeyT";
  Keys[Keys["KeyU"] = 85] = "KeyU";
  Keys[Keys["KeyV"] = 86] = "KeyV";
  Keys[Keys["KeyW"] = 87] = "KeyW";
  Keys[Keys["KeyX"] = 88] = "KeyX";
  Keys[Keys["KeyY"] = 89] = "KeyY";
  Keys[Keys["KeyZ"] = 90] = "KeyZ";
  Keys[Keys["NumpadDecimal"] = 110] = "NumpadDecimal";
  Keys[Keys["PageDown"] = 34] = "PageDown";
  Keys[Keys["PageUp"] = 33] = "PageUp";
  Keys[Keys["Shift"] = 16] = "Shift";
  Keys[Keys["Space"] = 32] = "Space";
  Keys[Keys["Tab"] = 9] = "Tab";
})(Keys || (Keys = {}));
class PreventableEvent {
  constructor() {
    this.prevented = false;
  }
  /**
   * Prevents the default action for a specified event.
   * In this way, the source component suppresses
   * the built-in behavior that follows the event.
   */
  preventDefault() {
    this.prevented = true;
  }
  /**
   * Returns `true` if the event was prevented
   * by any of its subscribers.
   *
   * @returns `true` if the default action was prevented.
   * Otherwise, returns `false`.
   */
  isDefaultPrevented() {
    return this.prevented;
  }
}
const propName = '--kendo-scrollbar-width';
/**
 * @hidden
 */
class ScrollbarWidthService {
  constructor() {
    this.changes = new EventEmitter();
    if (typeof window !== 'undefined' && isDocumentAvailable()) {
      document.body.style.setProperty(propName, `${getScrollbarWidth()}px`);
    }
  }
}
ScrollbarWidthService.ɵfac = function ScrollbarWidthService_Factory(t) {
  return new (t || ScrollbarWidthService)();
};
ScrollbarWidthService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ScrollbarWidthService,
  factory: ScrollbarWidthService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScrollbarWidthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DraggableDirective, DraggableModule, EventsModule, EventsOutsideAngularDirective, KendoInput, Keys, PreventableEvent, ResizeBatchService, ResizeCompatService, ResizeObserverService, ResizeSensorComponent, ResizeSensorModule, ScrollbarWidthService, anyChanged, closest, closestInScope, contains, findElement, findFocusable, findFocusableChild, getScrollbarWidth, guid, hasClasses, hasObservers, isChanged, isDocumentAvailable, isFocusable, isFocusableWithTabKey, isVisible, matchesClasses, matchesNodeName, rtlScrollPosition };