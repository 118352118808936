<div class="access-control">
  <div
    *ngIf="selectedUsersCount"
    class="selected-users"
  >
    <span class="selected-users-amount">
      Edit login security for <strong>{{ selectedUsersCount }} users</strong>
    </span>
    <button
      (click)="onEditLoginSecurityForMultipleUsers()"
      class="selected-users-edit"
    >
      Edit
    </button>
  </div>
  <div
    *ngIf="!isAdminRole && accessControlForm"
    class="access-message"
  >
    You do not have authorization to make changes to Access control.
  </div>
  <form
    *ngIf="users?.length && accessControlForm"
    [formGroup]="accessControlForm"
    [ngClass]="{
      'container-with-message': !isAdminRole,
      'h-100': isAdminRole
    }"
  >
    <div class="form-controls-container">
      <div
        *ngFor="let user of users; let i = index"
        [formGroupName]="user.id.toString()"
        class="access-control-option"
      >
        <div class="user-info-container">
          <div class="select-user">
            <span class="input-check">
              <pinnakl-input
                [form]="accessControlForm.controls[user.id.toString()]"
                checkBoxStyle="input-check"
                controlName="selected"
                type="boolean"
              >
              </pinnakl-input>
            </span>
          </div>
          <name-avatar
            [height]="'32px'"
            [nameString]="user.fullName"
            [width]="'32px'"
          ></name-avatar>
          <div class="user-name">
            <div class="name">
              <span>{{ user.fullName }}</span>
            </div>
            <div class="user-auth">
              <span class="authtype">{{ getUserAuthTypeShort(user).label }}</span>
              <span
                *ngIf="getUserAuthTypeShort(user).icon"
                class="icon {{ getUserAuthTypeShort(user).icon }}"
              ></span>
            </div>
          </div>
        </div>
        <div class="user-controls active-tooltip">
          <button
            (click)="onEditSingleUserLoginSecurity($event, user)"
            *ngIf="user.clientAdmin"
            class="login-security"
            data-tooltip="ADMIN"
          >
            <svg
              aria-hidden="true"
              class="svg-inline--fa fa-user-lock fa-w-20"
              data-icon="user-lock"
              data-prefix="fas"
              focusable="false"
              role="img"
              viewBox="0 0 640 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm96 64a63.08 63.08 0 0 1 8.1-30.5c-4.8-.5-9.5-1.5-14.5-1.5h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h280.9a63.54 63.54 0 0 1-8.9-32zm288-32h-32v-80a80 80 0 0 0-160 0v80h-32a32 32 0 0 0-32 32v160a32 32 0 0 0 32 32h224a32 32 0 0 0 32-32V320a32 32 0 0 0-32-32zM496 432a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm32-144h-64v-80a32 32 0 0 1 64 0z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
          <div
            [ngClass]="{ 'disabled-toggle': !isAdminRole }"
            class="toggle-control"
          >
            <pinnakl-input
              [controlName]="'tradingAccess' + user.id"
              [disabled]="!isAdminRole"
              [form]="accessControlForm.controls[user.id.toString()]"
              checkBoxStyle="toggle"
              label="Trading Access"
              type="boolean"
            >
            </pinnakl-input>
          </div>
          <div
            [ngClass]="{ 'disabled-toggle': !isAdminRole }"
            class="toggle-control"
          >
            <pinnakl-input
              [controlName]="'complianceAccess' + user.id"
              [disabled]="!isAdminRole"
              [form]="accessControlForm.controls[user.id.toString()]"
              checkBoxStyle="toggle"
              label="Compliance"
              type="boolean"
            >
            </pinnakl-input>
          </div>
          <div class="radioGroup">
            <span class="toggle-radio-btn">
              <input
                [attr.disabled]="!isAdminRole ? '' : undefined"
                [id]="'fullAccessLevel' + i"
                [value]="applicationAccessLevel.FULL_ACCESS"
                formControlName="applicationAccessLevel"
                type="radio"
              />
              <label
                (click)="accessRadioClick($event, user.id, ApplicationAccessLevel.FULL_ACCESS)"
                [for]="'fullAccessLevel' + i"
                >Full access</label
              >
            </span>
            <span class="toggle-radio-btn">
              <input
                [attr.disabled]="!isAdminRole ? '' : undefined"
                [id]="'viewOnlyLevel' + i"
                [value]="applicationAccessLevel.VIEW_ONLY"
                formControlName="applicationAccessLevel"
                type="radio"
              />
              <label
                (click)="accessRadioClick($event, user.id, ApplicationAccessLevel.VIEW_ONLY)"
                [for]="'viewOnlyLevel' + i"
                >View Only</label
              >
            </span>
            <span class="toggle-radio-btn">
              <input
                [attr.disabled]="!isAdminRole ? '' : undefined"
                [id]="'inactiveLevel' + i"
                [value]="applicationAccessLevel.INACTIVE"
                formControlName="applicationAccessLevel"
                type="radio"
              />
              <label
                (click)="accessRadioClick($event, user.id, ApplicationAccessLevel.INACTIVE)"
                [for]="'inactiveLevel' + i"
                >Inactive</label
              >
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="access-form-actions">
      <div>
        <button
          #anchor
          (click)="showFormCancelConfirmation()"
          [disabled]="!accessControlForm.dirty"
          class="btn btn-security btn-cancel"
          type="button"
        >
          CANCEL
        </button>
        <confirm-action
          (onCancelled)="cancelReset()"
          (onConfirmed)="resetForm()"
          [anchor]="anchor"
          [showConfirmation]="cancelConfirmationVisible"
          confirmationMessage="Are you sure you want to reset the form?"
        >
        </confirm-action>
      </div>
      <div>
        <button
          (click)="onSubmit()"
          [disabled]="!accessControlForm.dirty"
          class="btn btn-security btn-save"
          type="submit"
        >
          SAVE
        </button>
      </div>
    </div>
  </form>
</div>

<pinnakl-modal
  (closeModal)="toggleLoginSecurityModal()"
  [hideDialog]="hideLoginSecurityModal"
  [width]="632"
>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <login-security
          (cancelModal)="toggleLoginSecurityModal()"
          (updateUsers)="updateUsersLoginSecurity($event)"
          [users]="selectedUsers"
        ></login-security>
      </div>
    </div>
  </div>
</pinnakl-modal>
