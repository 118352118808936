import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { VdrUser, VdrUserStatusAction, VdrUserWithClientConfig } from '@pinnakl/vdr/domain';
import moment from 'moment';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VdrUsersApiService {
  private readonly _crmVdrUsersWithClientConfigEndpoint =
    'v3/entities/crm_vdr_users_with_clientconfig';
  private readonly _crmVdrUsersEndpoint = 'v3/entities/crm_vdr_user';
  private readonly _crmVdrUserAnalyticsEndpoint = 'v3/entities/crm_vdr_user_analytics';

  constructor(private readonly wsp: WebServiceProvider) {}

  getVdrUserById(id: number): Observable<VdrUserWithClientConfig> {
    return this.wsp.get<VdrUserWithClientConfig>({
      endpoint: `${this._crmVdrUsersWithClientConfigEndpoint}/${id}`
    });
  }

  getVdrUsers(): Promise<any[]> {
    return this.wsp.getHttp<VdrUserWithClientConfig[]>({
      endpoint: this._crmVdrUsersWithClientConfigEndpoint
    });
  }

  putVdrUser(body): Promise<VdrUserWithClientConfig> {
    return this.wsp.putHttp({
      endpoint: this._crmVdrUsersWithClientConfigEndpoint,
      body
    });
  }

  postVdrUserAction(
    vdrUserId: number,
    action: VdrUserStatusAction
  ): Promise<VdrUserWithClientConfig> {
    const reqBody = {
      action,
      id: vdrUserId
    };
    return this.postVdrUser(reqBody) as Promise<VdrUserWithClientConfig>;
  }

  deleteVdrUserAction(vdrUserId: number): Promise<{ key: string; value: number }[]> {
    const reqBody = {
      action: VdrUserStatusAction.Delete,
      id: vdrUserId
    };
    return this.postVdrUser(reqBody) as Promise<{ key: string; value: number }[]>;
  }

  postVdrUser(body): Promise<VdrUserWithClientConfig | { key: string; value: number }[]> {
    return this.wsp.postHttp({
      endpoint: this._crmVdrUsersWithClientConfigEndpoint,
      body
    });
  }

  initializeVdrUser(body): Promise<VdrUserWithClientConfig> {
    const {
      otpChannel,
      sendFileNotification,
      contactId,
      email,
      firstName,
      lastName,
      canAccessFundPerformance,
      canDownloadFiles
    } = body;
    const reqBody = {
      action: VdrUserStatusAction.Initialize,
      contactId,
      authType: 'TWO_FACTOR',
      otpChannel: 'EMAIL',
      sendFileNotification,
      email,
      firstName,
      lastName,
      canAccessFundPerformance,
      canDownloadFiles
    };
    return this.postVdrUser(reqBody) as Promise<VdrUserWithClientConfig>;
  }

  getVdrUserAnalytics(id: number) {
    return this.wsp
      .getHttp<any[]>({
        endpoint: this._crmVdrUserAnalyticsEndpoint,
        optionsParams: {
          filters: [
            {
              key: 'portalUserId',
              type: 'EQ',
              value: [id]
            }
          ]
        }
      })
      .then(analytics =>
        analytics.map(data => ({
          ...data,
          fileName: data.filename && data.filename.replace(/_[0-9]+/g, ''),
          timestamp: moment.utc(data.timestamp).local().toDate()
        }))
      );
  }

  // PORTAL

  getVdrPortalUserById(id: number): Observable<VdrUser> {
    return this.wsp.get<VdrUser>({
      endpoint: `${this._crmVdrUsersEndpoint}/${id}`
    });
  }

  putVdrPortalUser(body): Observable<VdrUser> {
    return this.wsp.put({
      endpoint: this._crmVdrUsersEndpoint,
      body
    });
  }

  updateVdrUser(id: number, data: Partial<VdrUser>): Observable<VdrUser> {
    return this.getVdrPortalUserById(id).pipe(
      switchMap(vdrUser =>
        this.putVdrPortalUser({
          ...vdrUser,
          ...data
        })
      )
    );
  }
}
