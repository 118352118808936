export const SHARED_DIALOG_CONFIG = {
  modal: true,
  position: 'center',
  baseZIndex: 10000,
  autoZIndex: true,
  closable: true,
  showHeader: false,
  closeOnEscape: true,
  dismissableMask: true
};

export const CUSTOM_ATTRIBUTES_SHARED_DIALOG_CONFIG = {
  ...SHARED_DIALOG_CONFIG,
  styleClass: 'custom-attribute-dialog',
  closable: true,
  width: '480px',
  showHeader: false,
  closeOnEscape: true,
  dismissableMask: true
};
