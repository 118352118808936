import { Pipe, PipeTransform } from '@angular/core';
import { OptionItem } from '@pinnakl/shared/types';

@Pipe({
  name: 'toOptions',
  standalone: true
})
export class DataToOptionsPipe implements PipeTransform {
  transform(
    data: any[],
    valueKey: string | 'this',
    labelKey: string,
    labelMap?: Record<string, string>
  ): OptionItem[] {
    if (!data || !data.length) return [];
    const isValueObject = valueKey === 'this';
    return data.map(obj => ({
      label: labelMap ? labelMap[obj[labelKey]] : obj[labelKey],
      value: isValueObject ? obj : obj[valueKey]
    }));
  }
}
