export enum FileTypeFormat {
  PPT = 'ppt',
  XLSX = 'xlsx',
  PDF = 'pdf',
  PNG = 'png',
  JPG = 'jpg',
  DOC = 'doc'
}

export const allowedFileTypeFormats = {
  [FileTypeFormat.PPT]: [
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ],
  [FileTypeFormat.XLSX]: [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel.sheet.macroEnabled.12'
  ],
  [FileTypeFormat.PDF]: ['application/pdf'],
  [FileTypeFormat.PNG]: ['image/png'],
  [FileTypeFormat.JPG]: ['image/jpeg'],
  [FileTypeFormat.DOC]: [
    '.doc',
    '.docx',
    'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ]
};
