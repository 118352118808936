import {
    deepExtend,
    addClass,
    keys,
    hasClasses,
    setDefaultOptions,
    renderIcon
} from '../common';

import {
    Observable
} from './scroller/observable';

import {
    on,
    off,
    setDefaultEvents,
    convertToHtml
} from './utils';

function createButton(direction, icon, iconOptions) {
    const html =
        '<button class="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-icon-button k-zoom-' + direction +
            '" title="zoom-' + direction +
            '" aria-label="zoom-' + direction + '">' +
            renderIcon({ icon: icon, iconClass: "k-button-icon", svgIcons: iconOptions.svgIcons, type: iconOptions.type }) +
        '</button>';

    return convertToHtml(html);
}

let PLUS = 187;
let MINUS = 189;
let FF_PLUS = 61;
let FF_MINUS = 173;
const CHANGE = "change";

export class ZoomControl extends Observable {
    constructor(element, options, iconOptions) {
        super();
        this.element = element;
        this._initOptions(options);

        const zoomInButton = createButton('in', 'plus', iconOptions);
        const zoomOutButton = createButton('out', 'minus', iconOptions);

        this.element.appendChild(zoomInButton);
        this.element.appendChild(zoomOutButton);

        addClass(this.element, 'k-widget k-zoom-control k-button-group k-group-horizontal');

        this._clickHandler = this._click.bind(this);
        on(this.element, "click", ".k-button", this._clickHandler);

        let parentElement = this.element.parentNode.closest("[data-role]");

        this._keyroot = parentElement ? parentElement : this.element;

        this._tabindex(this._keyroot);

        this._keydownHandler = this._keydown.bind(this);
        on(this._keyroot, "keydown", this._keydownHandler);
    }

    destroy() {
        if (this.element) {
            off(this.element, "click", this._clickHandler);
        }

        if (this._keyroot) {
            off(this._keyroot, 'keydown', this._keydownHandler);
        }
    }

    _tabindex(target) {
        const targetElement = target || this.wrapper || this.element;

        let element = this.element,
            TABINDEX = "tabindex",
            tabindex = targetElement.getAttribute(TABINDEX) || element.getAttribute(TABINDEX);

        element.removeAttribute(TABINDEX);

        targetElement.setAttribute(TABINDEX, !isNaN(tabindex) ? tabindex : 0);
    }

    _initOptions(options) {
        this.options = deepExtend({}, this.options, options);
    }

    _change(direction) {
        let zoomStep = this.options.zoomStep;
        this.trigger(CHANGE, { delta: direction * zoomStep });
    }

    _click(e) {
        let button = e.currentTarget;
        let direction = 1;

        if (hasClasses(button, 'k-zoom-out')) {
            direction = -1;
        }

        this._change(direction);
        e.preventDefault();
    }

    /* eslint-disable indent */
    _keydown(e) {
        switch (e.which) {
            case keys.NUMPAD_PLUS:
            case PLUS:
            case FF_PLUS:
                this._change(1);
                break;
            case keys.NUMPAD_MINUS:
            case MINUS:
            case FF_MINUS:
                this._change(-1);
                break;
            default:
                break;
        }
    }
    /* eslint-enable indent */
}

setDefaultOptions(ZoomControl, {
    name: 'ZoomControl',
    zoomStep: 1
});

setDefaultEvents(ZoomControl, [
    CHANGE
]);
