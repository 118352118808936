import { Component, Input } from '@angular/core';
import { AuthService } from '@pinnakl/auth/providers';
import { UserService } from '@pinnakl/core/data-providers';
import { AppNames } from '@pinnakl/shared/types';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { VdrUsersApiService } from '@pinnakl/vdr/data-access';

@Component({
  selector: 'change-password-page',
  templateUrl: 'change-password-page.component.html',
  styleUrls: ['./change-password-page.component.scss']
})
export class ChangePasswordPageComponent {
  @Input() projectName: AppNames;

  constructor(
    private readonly userService: UserService,
    private readonly vdrUsersApi: VdrUsersApiService,
    private readonly authService: AuthService,
    private readonly spinner: PinnaklSpinnerService,
    private readonly toastr: PinnaklUIToastMessage
  ) {}

  changePasswordSubmitted({ password }): void {
    if (this.projectName === AppNames.INVESTOR_PORTAL) {
      this.changePasswordForVdrUser(password);
    } else {
      this.changePasswordForInternalUser(password);
    }
  }

  changePasswordForInternalUser(password: string): void {
    this.spinner.spin();
    this.userService
      .putUser({
        password
      })
      .then(() => {
        this.authService.passwordResetDone();
        this.spinner.stop();
        this.toastr.success('Password changed successfully!');
      });
  }

  changePasswordForVdrUser(password: string): void {
    const { id } = this.userService.getUser();
    this.spinner.spin();
    this.vdrUsersApi.updateVdrUser(id, { password, passwordResetRequired: false }).subscribe(() => {
      this.authService.passwordResetDone();
      this.spinner.stop();
      this.toastr.success('Password changed successfully!');
    });
  }
}
