import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { PrimeButtonComponent } from '@pinnakl/shared/ui/prime';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';
import { SharedModule } from 'primeng/api';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  standalone: true,
  selector: 'empty-data-block',
  templateUrl: 'empty-data-block.component.html',
  imports: [
    InlineSVGModule,
    NgClass,
    SkeletonModule,
    PrimeButtonComponent,
    NgIf,
    SharedModule,
    NgTemplateOutlet
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyDataBlockComponent {
  @Input() title = '';
  @Input() titleStyleClass = '';
  @Input() buttonStyleClass = '';
  @Input() description = '';
  @Input() showButton = false;
  @Input() buttonLabel = '';
  @ContentChild('emptyIconTemplate', { static: false })
  emptyIconTemplate: TemplateRef<null> | null = null;
  @ContentChild('buttonTemplate', { static: false }) buttonTemplate: TemplateRef<null> | null =
    null;
  @Output() buttonClicked = new EventEmitter<void>();
}
