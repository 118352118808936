import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from '@pinnakl/core/data-providers';
import {
  ApplicationAccessLevel,
  TwoFactorType,
  User,
  UserAuthType,
  UserInfoApi
} from '@pinnakl/shared/types';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { filter, isEmpty } from 'lodash';
import { forkJoin, from, switchMap } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AccessControlService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'access-control',
  templateUrl: './access-control.component.html',
  styleUrls: ['./access-control.component.scss']
})
export class AccessControlComponent implements OnInit {
  ApplicationAccessLevel = ApplicationAccessLevel;
  readonly applicationAccessLevel = ApplicationAccessLevel;
  users: User[];
  accessControlForm: UntypedFormGroup;
  cancelConfirmationVisible = false;
  selectedUsers: User[] = [];
  selectedUserIds = [];
  hideLoginSecurityModal = true;
  selectedUsersCount: number;
  @Input() currentUser: User;

  get isAdminRole(): boolean {
    return !!this.currentUser?.clientAdmin;
  }

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly _accessControlService: AccessControlService,
    private readonly _userService: UserService,
    private readonly _spinner: PinnaklSpinnerService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _toastr: PinnaklUIToastMessage
  ) {}

  ngOnInit(): void {
    this.getAccessControlUsers();
  }

  showFormCancelConfirmation(): void {
    this.cancelConfirmationVisible = true;
  }

  cancelReset(): void {
    this.cancelConfirmationVisible = false;
  }

  resetForm(): void {
    this.initLoginSecurityForm();
    this.cancelReset();
  }

  onSubmit(): void {
    const changedUsersAccess = this.users
      .map((user: User) => {
        if (
          user.applicationAccessLevel !=
            this.accessControlForm.value[user.id.toString()].applicationAccessLevel ||
          user.tradingAccess !==
            this.accessControlForm.value[user.id.toString()][`tradingAccess${user.id}`] ||
          user.complianceAccess !==
            this.accessControlForm.value[user.id.toString()][`complianceAccess${user.id}`]
        ) {
          return {
            id: user.id.toString(),
            tradingaccess:
              this.accessControlForm.value[user.id.toString()][
                `tradingAccess${user.id}`
              ]?.toString(),
            applicationaccesslevel:
              this.accessControlForm.value[user.id.toString()].applicationAccessLevel ?? '',
            complianceaccess:
              this.accessControlForm.value[user.id.toString()][
                `complianceAccess${user.id}`
              ]?.toString()
          };
        }
        return null;
      })
      .filter(el => !isEmpty(el));

    this.updateUsersAccess(changedUsersAccess);
  }

  toggleLoginSecurityModal(): void {
    this.hideLoginSecurityModal = true;
  }

  onEditSingleUserLoginSecurity(event: any, user: User): void {
    event.stopPropagation();
    this.selectedUsers = [user];
    this.hideLoginSecurityModal = false;
  }

  onEditLoginSecurityForMultipleUsers(): void {
    this.selectedUsers = this.users.filter(
      user => !!this.selectedUserIds.find(id => id === user.id)
    );
    this.hideLoginSecurityModal = false;
  }

  updateUsersLoginSecurity(users: User[]): void {
    this.users = this.users.map(u => users.find(x => x.id === u.id) ?? u);
    this.hideLoginSecurityModal = true;
  }

  accessRadioClick(ev: Event, id: number, accessType: ApplicationAccessLevel): void {
    ev.stopPropagation();
    ev.preventDefault();

    this.accessControlForm.markAsDirty();
    const form = this.accessControlForm.get(id.toString());
    form?.patchValue({
      applicationAccessLevel: accessType === form.value.applicationAccessLevel ? null : accessType
    });
  }

  getUserAuthTypeShort(user) {
    switch (user.authType) {
      case UserAuthType.SINGLE_FACTOR:
        return { label: '1 FA', icon: null };
      case UserAuthType.TWO_FACTOR:
        return { label: '2 FA', icon: this.getIconAuthOtpChannel(user.otpChannel) };
      case UserAuthType.SSO:
        return { label: '2 FA', icon: this.getIconAuthOtpChannel(user.otpChannel) };
      default:
        return { label: '1 FA', icon: null };
    }
  }

  getIconAuthOtpChannel(otpchannel) {
    switch (otpchannel) {
      case TwoFactorType.EMAIL:
        return 'icon-pinnakl-email';
      case TwoFactorType.MOBILE:
        return 'icon-pinnakl-mobile';
      case TwoFactorType.QR:
        return 'icon-pinnakl-qr';
    }
    return '';
  }

  private getAccessControlUsers(): void {
    this._spinner.spin();
    this._accessControlService
      .getAccessControlUsers()
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this._spinner.stop();
        })
      )
      .subscribe(
        (users: User[]) => {
          this.users = users;
          this.resetForm();
        },
        (error: HttpErrorResponse) => {
          console.error('Error in get users', error);
          this._toastr.error('Error in get users');
        }
      );
  }

  private updateUsersAccess(collectionToSave: any): void {
    this._spinner.spin();
    forkJoin(collectionToSave.map(el => from(this._userService.putUser(el))) as [])
      .pipe(
        untilDestroyed(this),
        switchMap(() => this._accessControlService.getAccessControlUsers(true)),
        finalize(() => this._spinner.stop())
      )
      .subscribe(
        (users: User[]) => {
          const updatedUser = users.find(({ id }) => id === this._userService.getUser().id);
          if (updatedUser) {
            this._userService.setUser({
              ...this._userService.getUser(),
              tradingAccess: updatedUser.tradingAccess,
              applicationAccessLevel: updatedUser.applicationAccessLevel,
              complianceAccess: updatedUser.complianceAccess
            });
          }

          this.users = users;
          this.resetForm();

          this._toastr.success('Settings updated');
        },
        (error: HttpErrorResponse) => {
          console.error('Error while updating Access control', error);
          this._toastr.error('Error in changing');
        }
      );
  }

  private updateAccessControlUsers(updatedUsers: Partial<UserInfoApi>[]): User[] {
    const usersFromApi = this._accessControlService.setUsersFromApi(updatedUsers);
    return this.users.map(el => {
      const userFromApi = usersFromApi.find(({ id }) => id.toString() === el.id.toString());
      return userFromApi ?? el;
    });
  }

  private initLoginSecurityForm(): void {
    const group: any = {};
    this.users.forEach((user: User) => {
      group[user.id] = new UntypedFormGroup({
        applicationAccessLevel: new UntypedFormControl(user?.applicationAccessLevel),
        [`tradingAccess${user.id}`]: new UntypedFormControl({
          value: user?.tradingAccess,
          disabled: !this.isAdminRole
        }),
        [`complianceAccess${user.id}`]: new UntypedFormControl({
          value: user?.complianceAccess,
          disabled: !this.isAdminRole
        }),
        id: new UntypedFormControl(user.id),
        selected: new UntypedFormControl({ value: false, disabled: !this.isAdminRole })
      });
    });
    this.accessControlForm = this.fb.group(group);

    this.accessControlForm.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.checkSelectedUsers(value);
    });
  }

  private checkSelectedUsers(formValue: any): void {
    this.selectedUserIds = filter(formValue, x => x.selected).map(x => x.id);
    this.selectedUsersCount = this.selectedUserIds?.length;
  }
}
