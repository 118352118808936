import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostListener,
  inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { EnvironmentService } from '@pinnakl/core/environment';
import {
  CustomAttribute,
  CustomAttributeFeature,
  CustomAttributeTypeEnum
} from '@pinnakl/shared/custom-attributes/domain';
import {
  InputContainerComponent,
  InputValidationComponent,
  LabelContentComponent,
  PrimeButtonComponent,
  PrimeInputSwitchComponent,
  PrimeInputTextComponent,
  PrimeSelectButtonComponent
} from '@pinnakl/shared/ui/prime';
import { CustomValidators } from '@pinnakl/shared/util-helpers';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { BehaviorSubject } from 'rxjs';
import { CustomAttributeListOptionsComponent } from '../custom-attribute-list-options/custom-attribute-list-options.component';
import { BUTTONS_OPTIONS, ICONS_MAP } from '../custom-attributes-utils.helpers';

interface DialogConfig {
  itemNamesList: string[];
  attribute: CustomAttribute | null;
  feature: CustomAttributeFeature;
}

interface CustomAttributeForm {
  name: FormControl<string>;
  type: FormControl<CustomAttributeTypeEnum>;
  timeseries: FormControl<boolean>;
  listOptions: FormArray<FormControl<string>>;
  listOption: FormControl<string>;
  isMandatory: FormControl<boolean | null>;
}

@Component({
  selector: 'create-edit-custom-attribute-modal',
  standalone: true,
  templateUrl: './create-edit-custom-attribute-modal.component.html',
  styleUrls: ['./create-edit-custom-attribute-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PrimeButtonComponent,
    ReactiveFormsModule,
    InlineSVGModule,
    PrimeSelectButtonComponent,
    InputContainerComponent,
    InputValidationComponent,
    LabelContentComponent,
    PrimeInputTextComponent,
    PrimeInputSwitchComponent,
    InputTextModule,
    CustomAttributeListOptionsComponent,
    AsyncPipe,
    NgIf,
    TooltipModule
  ]
})
export class CreateEditCustomAttributeModalComponent {
  private readonly isProductionEnvironment = inject(EnvironmentService).isProdEnv;
  private readonly dialogRef: DynamicDialogRef | null = inject(DynamicDialogRef, {
    optional: true
  });
  private readonly dynamicDialogConfig: DynamicDialogConfig<DialogConfig> =
    inject(DynamicDialogConfig);
  private readonly destroyRef = inject(DestroyRef);
  private readonly itemNamesList: string[];
  private inputFocused = false;
  private readonly _items$ = new BehaviorSubject<string[]>([]);
  readonly attribute: CustomAttribute | null = null;
  readonly attributeTypes = CustomAttributeTypeEnum;
  readonly iconsMap = { ...ICONS_MAP };
  modelOptions: { label: string; value: CustomAttributeTypeEnum }[];
  isEdit = false;
  feature?: CustomAttributeFeature;
  customAttributeForm!: FormGroup<CustomAttributeForm>;
  readonly items$ = this._items$.asObservable();

  get typeControl(): FormControl<CustomAttributeTypeEnum> {
    return this.customAttributeForm.get('type') as FormControl<CustomAttributeTypeEnum>;
  }

  get listOptions(): FormArray<FormControl<string>> {
    return this.customAttributeForm?.get('listOptions') as FormArray<FormControl<string>>;
  }

  get listOption(): FormControl<string> {
    return this.customAttributeForm?.get('listOption') as FormControl<string>;
  }

  get withTimeSeries(): boolean {
    return this.feature === CustomAttributeFeature.Security;
  }

  get withMandatory(): boolean {
    return this.feature !== CustomAttributeFeature.Security;
  }

  constructor() {
    const { attribute, itemNamesList = [], feature } = this.dynamicDialogConfig?.data ?? {};
    this.attribute = attribute ?? null;
    this.isEdit = !!attribute?.id;
    this.feature = feature;
    this.itemNamesList = this.isEdit
      ? itemNamesList.filter(name => name !== this.attribute?.name)
      : itemNamesList;
    this.modelOptions = this.getModelOptions();
    this.initForm();
    this.listenToTypeChange();
    this.listenToListOptionsChange();
  }

  @HostListener('document:keydown.escape', ['$event']) onEscapeHandler(): void {
    if (!this.inputFocused) {
      this.close();
    }
  }

  onSubmit(): void {
    const { type, name, listOptions, timeseries, isMandatory } = this.customAttributeForm.value;
    this.dialogRef?.close({
      ...(this.attribute ?? {}),
      type,
      name,
      timeseries: this.isEdit ? this.attribute?.timeseries : timeseries,
      listOptions: listOptions
        ? listOptions.map((value, i) => ({ value, viewOrder: i + 1 }))
        : this.attribute?.listOptions,
      isMandatory
    });
  }

  listOptionDeleted(index: number): void {
    this.listOptions.removeAt(index);
  }

  listOptionsChange(items: string[]): void {
    items.map((item, i) => this.listOptions.at(i).setValue(item));
  }

  // wrong type of event from Angular
  onListOptionKeyDown(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    const input = event.target as HTMLInputElement;
    if (event instanceof KeyboardEvent && event.key === 'Escape') {
      return input?.blur();
    }
    this.listOption.updateValueAndValidity();
    if (!this.listOption.errors) {
      input?.value && this.listOptions.push(new FormControl(input.value, { nonNullable: true }));
      this.listOption.reset();
      this.customAttributeForm.updateValueAndValidity();
      if (event instanceof KeyboardEvent && event.key === 'Enter') {
        return input?.blur();
      }
    }
  }

  close(): void {
    this.dialogRef?.close();
  }

  enableOptionValidation(): void {
    this.inputFocused = true;
    const optionNamesValidator = (): ValidatorFn => {
      return (control: AbstractControl): { [key: string]: unknown } | null => {
        const index = this.listOptions.value.findIndex(
          i => i.toLowerCase() === control.value?.trim().toLowerCase()
        );
        return index >= 0 ? { viewAlreadyExists: true } : null;
      };
    };
    this.listOption?.setValidators(
      CustomValidators.nameValidators([optionNamesValidator()], {
        minLength: 1,
        maxLength: 25,
        required: true
      })
    );
    this.listOption.updateValueAndValidity();
  }

  disableOptionValidation(): void {
    this.inputFocused = false;
    this.listOption.clearValidators();
    this.listOption.updateValueAndValidity();
  }

  getModelOptions(): { label: string; value: CustomAttributeTypeEnum }[] {
    const buttonOptionsToUse = this.isProductionEnvironment
      ? BUTTONS_OPTIONS.filter(({ value }) => ![CustomAttributeTypeEnum.List].includes(value))
      : BUTTONS_OPTIONS;

    if (this.feature === CustomAttributeFeature.Security) {
      return buttonOptionsToUse.filter(
        option =>
          ![CustomAttributeTypeEnum.Url, CustomAttributeTypeEnum.Longtext].includes(option.value)
      );
    }
    return [...buttonOptionsToUse];
  }

  private initForm(): void {
    const itemNamesValidator = (): ValidatorFn => {
      return (control: AbstractControl): { [key: string]: unknown } | null => {
        const index = this.itemNamesList.findIndex(
          i => i.toLowerCase() === control.value?.trim().toLowerCase()
        );
        return index >= 0 ? { viewAlreadyExists: true } : null;
      };
    };
    this.customAttributeForm = new FormGroup<CustomAttributeForm>({
      type: new FormControl(this.attribute?.type ?? CustomAttributeTypeEnum.List, {
        nonNullable: true,
        validators: this.isEdit ? [] : [Validators.required]
      }),
      name: new FormControl(this.attribute?.name ?? '', {
        nonNullable: true,
        validators: CustomValidators.nameValidators([itemNamesValidator()], {
          minLength: 3,
          maxLength: 35,
          required: true
        })
      }),
      timeseries: new FormControl(this.attribute?.timeseries ?? false, {
        nonNullable: true
      }),
      listOptions: new FormArray(
        (this.attribute?.listOptions ?? [])?.map(
          o => new FormControl(o.value, { nonNullable: true })
        )
      ),
      listOption: new FormControl('', { nonNullable: true }),
      isMandatory: new FormControl<boolean | null>(this.attribute?.isMandatory ?? false)
    });
    if (this.isEdit) {
      this.customAttributeForm.get('type')?.disable();
      this.customAttributeForm.get('timeseries')?.disable();
    }
    this._items$.next((this.attribute?.listOptions || []).map(o => o.value));
    this.toggleListValidation(this.typeControl.value);
  }

  private listenToTypeChange(): void {
    this.typeControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => this.toggleListValidation(value));
  }

  private listenToListOptionsChange(): void {
    this.listOptions.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(values => {
      this._items$.next(values || []);
    });
  }

  private toggleListValidation(value: CustomAttributeTypeEnum): void {
    if (value === CustomAttributeTypeEnum.List) {
      this.listOptions?.setValidators([CustomValidators.minEntities(1)]);
    } else {
      this.listOptions?.clearValidators();
    }
    this.listOptions.updateValueAndValidity();
  }
}
