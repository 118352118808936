<div class="login-security">
  <h2 class="title">{{ userAuthTypes.multiFactor.label }}</h2>
  <form
    (ngSubmit)="form.valid && onSubmit()"
    [formGroup]="form"
  >
    <div>
      <div class="multi-factor-controls">
        <div class="multi-factor-header">ONE TIME PASSWORD</div>
        <div>
          <div class="radioGroup">
            <span class="toggle-radio-btn">
              <input
                [value]="multiFactorTypes.email.value"
                formControlName="otpChannel"
                id="multiFactorEmail"
                type="radio"
              />
              <label for="multiFactorEmail">{{ multiFactorTypes.email.label }}</label>
            </span>
            <span class="toggle-radio-btn">
              <input
                [value]="multiFactorTypes.mobile.value"
                formControlName="otpChannel"
                id="multiFactorMobile"
                type="radio"
              />
              <label for="multiFactorMobile">{{ multiFactorTypes.mobile.label }}</label>
            </span>
          </div>
        </div>
      </div>

      <div
        *ngIf="form.get('otpChannel').errors"
        class="twofactor-users-warning"
      >
        <span>
          <svg
            height="12"
            viewBox="0 0 12 12"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              fill="none"
              fill-rule="evenodd"
            >
              <g>
                <g>
                  <g>
                    <g
                      transform="translate(-727 -300) translate(669 160) translate(30 114) translate(28 26)"
                    >
                      <circle
                        cx="6"
                        cy="6"
                        fill="#FF9213"
                        r="6"
                      />
                      <path
                        d="M5 2H7V7H5zM5 8H7V10H5z"
                        fill="#FFF"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
        <span class="twofactor-users-warning-text">
          You can’t set a 2 factor authentication because you have not specified
          {{ form.get('otpChannel').errors['otpChannel'] }}
        </span>
      </div>
    </div>

    <div class="row justify-content-between m-0 p-0">
      <div>
        <button
          #anchor
          (click)="showFormCancelConfirmation()"
          [disabled]="!form.dirty"
          class="btn btn-security btn-cancel"
          type="button"
        >
          CANCEL
        </button>
        <confirm-action
          (onCancelled)="cancelReset()"
          (onConfirmed)="resetForm()"
          [anchor]="anchor"
          [showConfirmation]="cancelConfirmationVisible"
          confirmationMessage="Are you sure you want to reset the form?"
        >
        </confirm-action>
      </div>
      <div>
        <button
          [disabled]="!form.dirty || !form.valid"
          class="btn btn-security btn-save"
          type="submit"
        >
          SAVE
        </button>
      </div>
    </div>
  </form>
</div>
