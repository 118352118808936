import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { PrimeButtonComponent, SimpleYesNoModalComponent } from '@pinnakl/shared/ui/prime';
import { AutoFocusModule } from 'primeng/autofocus';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  standalone: true,
  templateUrl: './delete-custom-attribute-modal.component.html',
  imports: [SimpleYesNoModalComponent, PrimeButtonComponent, AutoFocusModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteCustomAttributeModalComponent {
  private readonly dialogRef?: DynamicDialogRef | null = inject(DynamicDialogRef, {
    optional: true
  });
  public readonly config: DynamicDialogConfig<{ header: string; content: string }> =
    inject(DynamicDialogConfig);

  delete(): void {
    this.dialogRef?.close(true);
  }

  close(): void {
    this.dialogRef?.close(false);
  }
}
