<h3>Vdr Configuration</h3>
<form
  (ngSubmit)="submit()"
  *ngIf="form"
  [formGroup]="form"
  class="form d-flex flex-column justify-content-between"
>
  <div class="wrap">
    <div class="mt-3">
      <pinnakl-input
        [form]="form"
        [showValidation]="false"
        checkBoxStyle="toggle"
        controlName="sendFileUploadNotifications"
        label="Send notifications for file upload"
        type="boolean"
      >
      </pinnakl-input>
    </div>
    <div class="mt-3">
      <pinnakl-input
        [form]="form"
        [showValidation]="false"
        checkBoxStyle="toggle"
        controlName="accessFundPerformance"
        label="Access fund performance "
        type="boolean"
      >
      </pinnakl-input>
    </div>
    <div class="mt-3">
      <pinnakl-input
        [form]="form"
        [showValidation]="false"
        checkBoxStyle="toggle"
        controlName="allowFileDownload"
        label="Allow file download"
        type="boolean"
      >
      </pinnakl-input>
    </div>
    <div class="mt-3">
      <pinnakl-input
        [form]="form"
        [showValidation]="false"
        checkBoxStyle="toggle"
        controlName="allowFileUpload"
        label="Allow file upload"
        type="boolean"
      >
      </pinnakl-input>
    </div>
    <div class="mt-3">
      <pinnakl-input
        [dropdownOptions]="{
          modelProperty: 'id',
          viewProperty: 'fullName'
        }"
        [dropdownSource]="users"
        [form]="form"
        [showValidation]="false"
        controlName="activationEmailUserId"
        label="Account activation email sender"
        type="dropdown"
      >
      </pinnakl-input>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <button
      class="button-container blue action-button"
      type="submit"
    >
      Save
    </button>
  </div>
</form>
