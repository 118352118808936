import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { Observable } from 'rxjs';

interface EmailConfigPostPayload {
  saveSentItemsInOutlookEnabled: boolean;
  id: number;
}

interface ClientConfigPostPayload {
  id: number;
  testCampaignsBeforeSending: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CRMClientConfigService {
  private readonly clientConfigEndpoint = `${V3_ENDPOINT}crm_client_config`;
  private readonly emailConfigEndpoint = `${V3_ENDPOINT}crm_email_campaign_clientconfig`;
  private readonly _crmEmailProviderTestContentEndpoint = `${V3_ENDPOINT}crm_email_provider_test_content`;
  private readonly _crmEmailProviderIntegrationTestEndpoint = `${V3_ENDPOINT}email_integration_test`;

  constructor(private readonly wsp: WebServiceProvider) {}

  putClientConfig(payload: ClientConfigPostPayload): Observable<any> {
    return this.wsp.put({
      endpoint: this.clientConfigEndpoint,
      body: payload
    });
  }

  putEmailConfig(payload: EmailConfigPostPayload): Observable<any> {
    return this.wsp.put({
      endpoint: this.emailConfigEndpoint,
      body: payload
    });
  }

  sendTestEmailContent(
    payload: {
      fromUserId: string;
      emailContent: string;
      clientFileId?: string;
    },
    emails: { email: string }[]
  ): Promise<any> {
    return this.wsp.postHttp({
      endpoint: this._crmEmailProviderTestContentEndpoint,
      body: {
        fromUserId: payload.fromUserId,
        emailContent: payload.emailContent,
        crm_email_provider_test_emailaddresses: emails.map(el => ({ emailAddress: el.email }))
      }
    });
  }

  sendCRMEmailProviderIntegrationTest(emailId: string): Promise<
    {
      exceptionMessage: string | null;
      exceptionType: string | null;
    }[]
  > {
    return this.wsp.postHttp({
      endpoint: this._crmEmailProviderIntegrationTestEndpoint,
      body: {
        emailId
      }
    });
  }
}
