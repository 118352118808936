import { Component, HostBinding, inject, Input, OnInit, signal } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FileService } from '@pinnakl/core/data-providers';
import { CrmOptionsStore } from '@pinnakl/crm/data-access';
import { CrmClientConfig, CrmEmailClientConfig } from '@pinnakl/crm/domain';
import { User } from '@pinnakl/shared/types';
import { NylasEmailsCollectorService } from '@pinnakl/shared/util-external-services';
import {
  PinnaklSpinnerService,
  PinnaklUIToastMessage,
  ShowErrorService
} from '@pinnakl/shared/util-providers';
import { cloneDeep } from 'lodash';
import { combineLatest } from 'rxjs';
import { CRMClientConfigService } from '../../services';
import { TestEmailTemplateModalComponent } from './test-email-template-modal/test-email-template-modal.component';

enum ClientType {
  O365 = 'O365',
  Gsuite = 'Gsuite'
}

@Component({
  selector: 'email-campaign-config-settings',
  templateUrl: './email-campaign-config-settings.component.html',
  styleUrls: ['./email-campaign-config-settings.component.scss']
})
export class EmailCampaignConfigSettingsComponent implements OnInit {
  private readonly nylasEmailsCollectorService = inject(NylasEmailsCollectorService);
  private readonly crmOptionsStore = inject(CrmOptionsStore);
  readonly isIntegratedAlready = this.nylasEmailsCollectorService.isIntegratedAlready;
  readonly isNylasIntegrationLoading = signal(false);
  public form: UntypedFormGroup;
  public clientType = ClientType;
  @Input() users: User[];
  @Input() clientConfig: CrmClientConfig;
  @Input() emailClientConfig: CrmEmailClientConfig;
  testEmail = {
    message: '',
    isError: false
  };

  @HostBinding('class') componentClasses = 'overflow-y-auto';

  constructor(
    private readonly crmClientConfigService: CRMClientConfigService,
    private readonly pinnaklSpinner: PinnaklSpinnerService,
    private readonly showErrorService: ShowErrorService,
    private readonly fb: UntypedFormBuilder,
    private readonly toastr: PinnaklUIToastMessage,
    private readonly dialog: MatDialog,
    private readonly fileService: FileService
  ) {}

  ngOnInit(): void {
    this.initEmailCampaingForm();
    this.checkNylasIntegration();
  }

  openTestEmailModal() {
    const dialogRef = this.dialog.open<TestEmailTemplateModalComponent, MatDialogConfig>(
      TestEmailTemplateModalComponent,
      {
        width: '632px',
        data: {
          data: {
            users: cloneDeep(this.users)
          }
        }
      }
    );
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.saveTestEmailTemplate(res);
      }
    });
  }

  async saveTestEmailTemplate(res: any) {
    this.pinnaklSpinner.spin();
    try {
      const payload = { ...res };
      payload.fromUserId = payload.fromUserId.toString();
      delete payload.emails;
      delete payload.file;

      if (res.file?.file) {
        const uploadedFile = await this.fileService.upload(res.file);
        payload['clientFileId'] = uploadedFile.id.toString();
      }

      const responseTestContent = await this.crmClientConfigService.sendTestEmailContent(
        payload,
        res.emails
      );
      const responeIntegrationTest =
        await this.crmClientConfigService.sendCRMEmailProviderIntegrationTest(
          responseTestContent.id
        );
      const errorSend = responeIntegrationTest.find(res => res.exceptionMessage);
      if (errorSend) {
        this.testEmail = {
          message: `Email failed with the following error message - ${errorSend.exceptionMessage}`,
          isError: true
        };
      } else {
        this.testEmail = {
          message: 'Email was successfully sent',
          isError: false
        };
      }
      this.pinnaklSpinner.stop();
    } catch (error) {
      this.showErrorService.showError(error as any);
    }
  }

  initEmailCampaingForm() {
    this.form = this.fb.group({
      testCampaignsBeforeSending: this.clientConfig.testCampaignsBeforeSending ?? '',
      saveSentItemsInOutlookEnabled: this.emailClientConfig.saveSentItemsInOutlookEnabled ?? '',
      clientType: this.emailClientConfig.clientType ?? '',
      apiKeyValue: this.emailClientConfig.apiSecretKeyValue ?? '',
      applicationId: this.emailClientConfig.applicationId ?? '',
      tenantId: this.emailClientConfig.tenantId ?? ''
    });
  }

  saveCRMClientConfig() {
    this.pinnaklSpinner.spin();
    const formData = this.form.getRawValue();
    const emailConfig = {
      id: this.emailClientConfig.id,
      saveSentItemsInOutlookEnabled: formData.saveSentItemsInOutlookEnabled
    };
    const crmConfig = {
      id: this.clientConfig.id,
      testCampaignsBeforeSending: formData.testCampaignsBeforeSending
    };
    combineLatest([
      this.crmClientConfigService.putClientConfig(crmConfig),
      this.crmClientConfigService.putEmailConfig(emailConfig)
    ]).subscribe({
      next: () => this.toastr.success('Email campaign configuration has been saved'),
      error: e => {
        this.showErrorService.showError(e as any);
        this.pinnaklSpinner.stop();
      },
      complete: () => {
        this.pinnaklSpinner.stop();
        this.crmOptionsStore.loadCrmOptions(true);
      }
    });
  }

  async integrateOffice365WithNylas(): Promise<void> {
    await this.nylasEmailsCollectorService.integrate();
  }

  private async checkNylasIntegration(): Promise<void> {
    this.isNylasIntegrationLoading.set(true);
    const status = await this.nylasEmailsCollectorService.getIntegrationStatus();
    this.nylasEmailsCollectorService.setIntegrationStatus(status);
    this.isNylasIntegrationLoading.set(false);
  }
}
