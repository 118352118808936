import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getLoadableEntities$ } from '@pinnakl/shared/util-helpers';
import { CrmVdrConfig } from '@pinnakl/vdr/domain';
import { Observable } from 'rxjs';
import { State, vdrConfigQuery } from '../store';
import { LoadVdrConfig, SetVdrConfig } from '../store/vdr-config/vdr-config.actions';
import { VdrConfigApiService } from './vdr-config-api.service';

@Injectable({
  providedIn: 'root'
})
export class VdrConfigFacadeService {
  vdrConfig$ = getLoadableEntities$<CrmVdrConfig | null>(
    this.store.select(vdrConfigQuery.selectVdrConfig),
    this.store.select(vdrConfigQuery.selectVdrConfigLoaded),
    this.store.select(vdrConfigQuery.selectVdrConfigLoading),
    this.loadVdrConfig.bind(this)
  );

  constructor(
    private readonly store: Store<State>,
    private readonly vdrConfigApi: VdrConfigApiService
  ) {}

  loadVdrConfig(): void {
    this.store.dispatch(LoadVdrConfig());
  }

  setVdrConfig(config: CrmVdrConfig): void {
    this.store.dispatch(SetVdrConfig({ config }));
  }

  postCrmVdrConfig(config: Partial<CrmVdrConfig>): Observable<CrmVdrConfig> {
    return this.vdrConfigApi.postCrmVdrConfig(config);
  }

  putCrmVdrConfig(config: Partial<CrmVdrConfig>): Observable<CrmVdrConfig> {
    return this.vdrConfigApi.putCrmVdrConfig(config);
  }

  getCrmVdrConfigs(): Observable<CrmVdrConfig[]> {
    return this.vdrConfigApi.getCrmVdrConfigs();
  }
}
