<div class="account-settings">
  <form
    (ngSubmit)="accountForm.valid && onSubmit()"
    [formGroup]="accountForm"
  >
    <div>
      <div class="row">
        <div class="account-settings-control">
          <prime-input-container>
            <ng-template #labelContent>
              <prime-label-content
                formControlName="username"
                label="User name"
              ></prime-label-content>
            </ng-template>
            <ng-template #inputContent>
              <prime-text-input
                class="text-sm"
                formControlName="username"
              >
              </prime-text-input>
            </ng-template>
            <ng-template #validationContent>
              <prime-input-validation
                class="mt-1"
                formControlName="username"
              ></prime-input-validation>
            </ng-template>
          </prime-input-container>
        </div>
        <div class="account-settings-control">
          <prime-input-container>
            <ng-template #labelContent>
              <prime-label-content
                formControlName="email"
                label="Email"
              ></prime-label-content>
            </ng-template>
            <ng-template #inputContent>
              <prime-text-input
                class="text-sm"
                formControlName="email"
              >
              </prime-text-input>
            </ng-template>
            <ng-template #validationContent>
              <prime-input-validation
                [customErrors]="{
                  pattern: 'Email is not valid.'
                }"
                class="mt-1"
                formControlName="email"
              ></prime-input-validation>
            </ng-template>
          </prime-input-container>
        </div>
      </div>
      <div class="row">
        <div class="account-settings-control">
          <prime-input-container>
            <ng-template #labelContent>
              <prime-label-content
                formControlName="firstName"
                label="First name"
              ></prime-label-content>
            </ng-template>
            <ng-template #inputContent>
              <prime-text-input
                class="text-sm"
                formControlName="firstName"
              >
              </prime-text-input>
            </ng-template>
            <ng-template #validationContent>
              <prime-input-validation
                class="mt-1"
                formControlName="firstName"
              ></prime-input-validation>
            </ng-template>
          </prime-input-container>
        </div>
        <div class="account-settings-control">
          <prime-input-container>
            <ng-template #labelContent>
              <prime-label-content
                formControlName="lastName"
                label="Last name"
              ></prime-label-content>
            </ng-template>
            <ng-template #inputContent>
              <prime-text-input
                class="text-sm"
                formControlName="lastName"
              >
              </prime-text-input>
            </ng-template>
            <ng-template #validationContent>
              <prime-input-validation
                class="mt-1"
                formControlName="lastName"
              ></prime-input-validation>
            </ng-template>
          </prime-input-container>
        </div>
      </div>
      <div class="row">
        <div class="account-settings-control">
          <prime-input-container>
            <ng-template #labelContent>
              <prime-label-content
                formControlName="phone"
                label="Phone number"
              ></prime-label-content>
            </ng-template>
            <ng-template #inputContent>
              <prime-mask-input
                formControlName="phone"
                mask="+9-999-999-9999"
                styleClass="w-100 text-sm"
              >
              </prime-mask-input>
            </ng-template>
            <ng-template #validationContent>
              <prime-input-validation
                [customErrors]="{
                  pattern: 'Phone is not valid.'
                }"
                class="mt-1"
                formControlName="phone"
              ></prime-input-validation>
            </ng-template>
          </prime-input-container>
        </div>
        <div class="account-settings-control">
          <prime-input-container>
            <ng-template #labelContent>
              <prime-label-content
                formControlName="timezone"
                label="Time zone"
              ></prime-label-content>
            </ng-template>
            <ng-template #inputContent>
              <prime-single-dropdown
                [options]="availableTimezones | toOptions: 'timeZone' : 'timeZone'"
                formControlName="timezone"
                styleClass="w-100 text-sm"
              ></prime-single-dropdown>
            </ng-template>
            <ng-template #validationContent>
              <prime-input-validation
                class="mt-1"
                formControlName="timezone"
              ></prime-input-validation>
            </ng-template>
          </prime-input-container>
        </div>
      </div>
    </div>

    <div class="row justify-content-between m-0 p-0">
      <div>
        <button
          #anchor
          (click)="showFormCancelConfirmation()"
          [disabled]="!accountForm.dirty"
          class="btn btn-security btn-cancel"
          type="button"
        >
          CANCEL
        </button>
        <confirm-action
          (onCancelled)="cancelReset()"
          (onConfirmed)="resetForm()"
          [anchor]="anchor"
          [showConfirmation]="cancelConfirmationVisible"
          confirmationMessage="Are you sure you want to reset the form?"
        >
        </confirm-action>
      </div>
      <div>
        <button
          [disabled]="!accountForm.dirty || !accountForm.valid"
          class="btn btn-security btn-save"
          type="submit"
        >
          SAVE
        </button>
      </div>
    </div>
  </form>
</div>
