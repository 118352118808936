<custom-attributes-table
  [customAttributes]="customAttributes()"
  [loading]="loading()"
  [processing]="processing()"
  [withMandatoryColumn]="withMandatory"
  (editEvent)="editAttribute($event)"
  (deleteEvent)="deleteAttribute($event)"
  (createEvent)="createAttribute()"
>
</custom-attributes-table>
@if (customAttributes().length) {
  <div class="w-full mt-3">
    <prime-button
      styleClass="w-full"
      (onClick)="createAttribute()"
      label="Add New Attribute"
    ></prime-button>
  </div>
}
