import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getLoadableEntities$, isNeitherNullNorUndefined } from '@pinnakl/shared/util-helpers';
import { VdrUser, VdrUserStatusAction, VdrUserWithClientConfig } from '@pinnakl/vdr/domain';
import { Observable, filter } from 'rxjs';
import {
  State,
  selectAllVdrUsers,
  selectVdrUserById,
  selectVdrUsersLoaded,
  selectVdrUsersLoading
} from '../store';
import {
  DeleteVdrUsers,
  LoadVdrUsers,
  SetVdrUsers,
  UpdateVdrUsers
} from '../store/vdr-users/vdr-users.actions';
import { VdrUsersApiService } from './vdr-users-api.service';

@Injectable({
  providedIn: 'root'
})
export class VdrUsersFacadeService {
  vdrUsers$ = getLoadableEntities$<VdrUserWithClientConfig[]>(
    this.store.select(selectAllVdrUsers).pipe(filter(isNeitherNullNorUndefined)),
    this.store.select(selectVdrUsersLoaded),
    this.store.select(selectVdrUsersLoading),
    this.loadVdrUsers.bind(this)
  );

  constructor(
    private readonly store: Store<State>,
    private readonly api: VdrUsersApiService
  ) {}

  selectVdrUserById(id): Observable<VdrUserWithClientConfig | undefined> {
    return this.store.select(selectVdrUserById(id));
  }

  loadVdrUsers(): void {
    this.store.dispatch(LoadVdrUsers());
  }

  setVdrUsers(vdrUsers: VdrUserWithClientConfig[]): void {
    this.store.dispatch(SetVdrUsers({ vdrUsers }));
  }

  updateVdrUsers(vdrUsers: VdrUserWithClientConfig[]): void {
    this.store.dispatch(UpdateVdrUsers({ vdrUsers }));
  }

  deleteVdrUsers(vdrUserIds: number[]): void {
    this.store.dispatch(DeleteVdrUsers({ vdrUserIds }));
  }

  getVdrUserById(id: number): Observable<VdrUserWithClientConfig> {
    return this.api.getVdrUserById(id);
  }

  getVdrUserAnalytics(id: number): Promise<any[]> {
    return this.api.getVdrUserAnalytics(id);
  }

  deleteVdrUserAction(vdrUserId: number): Promise<{ key: string; value: number }[]> {
    return this.api.deleteVdrUserAction(vdrUserId);
  }

  postVdrUserAction(
    vdrUserId: number,
    action: VdrUserStatusAction
  ): Promise<VdrUserWithClientConfig> {
    return this.api.postVdrUserAction(vdrUserId, action);
  }

  putVdrUser(body): Promise<VdrUserWithClientConfig> {
    return this.api.putVdrUser(body);
  }

  getVdrUsers(): Promise<any[]> {
    return this.api.getVdrUsers();
  }

  initializeVdrUser(body): Promise<VdrUserWithClientConfig> {
    return this.api.initializeVdrUser(body);
  }

  // PORTAL

  getVdrPortalUserById(id: number): Observable<VdrUser> {
    return this.api.getVdrPortalUserById(id);
  }

  putVdrPortalUser(body): Observable<VdrUser> {
    return this.api.putVdrPortalUser(body);
  }
}
