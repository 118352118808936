<div class="login-security">
  <form
    (ngSubmit)="loginSecurityForm.valid && onSubmit()"
    [formGroup]="loginSecurityForm"
  >
    <div>
      <div class="row auth-type-controls">
        <div class="login-security-control">
          <input
            [value]="userAuthTypes.singleFactor.value"
            class="radio-input"
            formControlName="authType"
            id="singleFactorAuth"
            type="radio"
          />
          <label
            class="radio-label"
            for="singleFactorAuth"
            >{{ userAuthTypes.singleFactor.label }}</label
          >
        </div>
        <div class="login-security-control">
          <div>
            <input
              [attr.disabled]="
                !forMultipleUsers && (!users[0]?.email || !users[0]?.phone) ? '' : undefined
              "
              [value]="userAuthTypes.multiFactor.value"
              class="radio-input"
              formControlName="authType"
              id="multiFactorAuth"
              type="radio"
            />
            <label
              class="radio-label"
              for="multiFactorAuth"
              >{{ userAuthTypes.multiFactor.label }}</label
            >
          </div>
          <div
            *ngIf="!forMultipleUsers && (!users[0]?.email || !users[0]?.phone)"
            class="pt-1"
          >
            &nbsp;(To use this option, please set the correct email and phone)
          </div>
        </div>

        <ng-container
          *ngIf="
            forMultipleUsers &&
            ((loginSecurityForm.get('otpChannel').value === multiFactorTypes.email.value &&
              usersWithMissedEmail?.length) ||
              (loginSecurityForm.get('otpChannel').value === multiFactorTypes.mobile.value &&
                usersWithMissedPhone?.length))
          "
        >
          <div class="twofactor-users-warning">
            <span
              ><svg
                height="12"
                viewBox="0 0 12 12"
                width="12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g>
                    <g>
                      <g>
                        <g
                          transform="translate(-727 -300) translate(669 160) translate(30 114) translate(28 26)"
                        >
                          <circle
                            cx="6"
                            cy="6"
                            fill="#FF9213"
                            r="6"
                          />
                          <path
                            d="M5 2H7V7H5zM5 8H7V10H5z"
                            fill="#FFF"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            <span class="twofactor-users-warning-text">
              You can’t set a 2 factor authentication for the following users because they are
              missing
              <ng-container
                *ngIf="loginSecurityForm.get('otpChannel').value === multiFactorTypes.email.value"
              >
                an email: <strong>{{ usersFullNamesWithMissedEmail }}</strong>
              </ng-container>
              <ng-container
                *ngIf="loginSecurityForm.get('otpChannel').value === multiFactorTypes.mobile.value"
              >
                a phone number: <strong>{{ usersFullNamesWithMissedPhone }}</strong>
              </ng-container>
            </span>
          </div>
        </ng-container>

        <div class="multi-factor-controls">
          <span class="multi-factor-header">ONE TIME PASSWORD</span>
          <div>
            <div class="radioGroup">
              <span class="toggle-radio-btn">
                <input
                  [attr.disabled]="!isMultiFactorSelected ? '' : undefined"
                  [value]="multiFactorTypes.email.value"
                  formControlName="otpChannel"
                  id="multiFactorEmail"
                  type="radio"
                />
                <label for="multiFactorEmail">{{ multiFactorTypes.email.label }}</label>
              </span>
              <span class="toggle-radio-btn">
                <input
                  [attr.disabled]="!isMultiFactorSelected ? '' : undefined"
                  [value]="multiFactorTypes.mobile.value"
                  formControlName="otpChannel"
                  id="multiFactorMobile"
                  type="radio"
                />
                <label for="multiFactorMobile">{{ multiFactorTypes.mobile.label }}</label>
              </span>
              <span class="toggle-radio-btn">
                <input
                  [attr.disabled]="!isMultiFactorSelected ? '' : undefined"
                  [value]="multiFactorTypes.qr.value"
                  formControlName="otpChannel"
                  id="multiFactorQr"
                  type="radio"
                />
                <label for="multiFactorQr">{{ multiFactorTypes.qr.label }}</label>
              </span>
            </div>
            <div *ngIf="{ qrCodeSecret: qrCodeSecret$ | async } as data">
              <div
                *ngIf="this.users?.[0]?.otpChannel === 'QR'"
                [ngClass]="{ disabled: !isMultiFactorSelected }"
                class="qr-toggle-text"
              >
                <a
                  (click)="isMultiFactorSelected && showQrModal()"
                  *ngIf="data.qrCodeSecret"
                  >Show QR code</a
                >
                <a (click)="isMultiFactorSelected && updateQrSecret()"
                  >{{ data.qrCodeSecret ? 'Update' : 'Create' }} existing QR code</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="sso-container">
          <div class="login-security-control">
            <input
              [attr.disabled]="true"
              [value]="userAuthTypes.sso.value"
              class="radio-input"
              formControlName="authType"
              id="ssoAuth"
              type="radio"
            />
            <label
              class="radio-label"
              for="ssoAuth"
              >{{ userAuthTypes.sso.label }}</label
            >
          </div>
          <p class="sso-description">
            Single sign-on enables users to access both the on-premises and Microsoft 365 or Office
            365 organizations with a single username and password
          </p>
        </div>
      </div>
      <hr />
      <div class="re-auth-interval-container form-group">
        <span class="re-auth-interval-header">Request authentication</span>
        <div>Idle time span after which your authentication token will expire</div>
        <div class="radioGroup">
          <span
            *ngFor="let authRequestTime of requestAuthenticationHours; let i = index"
            class="toggle-radio-btn"
          >
            <input
              [id]="'tokenReAuth' + i"
              [value]="authRequestTime"
              formControlName="tokenReAuthInterval"
              type="radio"
            />
            <label [for]="'tokenReAuth' + i">Every {{ authRequestTime }} hrs</label>
          </span>
        </div>
      </div>
    </div>

    <div class="row justify-content-between m-0 p-0">
      <div>
        <button
          #anchor
          (click)="forMultipleUsers ? onCancelModal() : showFormCancelConfirmation()"
          [disabled]="!forMultipleUsers && !loginSecurityForm.dirty"
          class="btn btn-security btn-cancel"
          type="button"
        >
          CANCEL
        </button>
        <confirm-action
          (onCancelled)="cancelReset()"
          (onConfirmed)="resetForm()"
          [anchor]="anchor"
          [showConfirmation]="cancelConfirmationVisible"
          confirmationMessage="Are you sure you want to reset the form?"
        >
        </confirm-action>
      </div>
      <div>
        <button
          [disabled]="!loginSecurityForm.dirty || !loginSecurityForm.valid"
          class="btn btn-security btn-save"
          type="submit"
        >
          SAVE
        </button>
      </div>
    </div>
  </form>
</div>

<pinnakl-modal
  (closeModal)="closeQrModal()"
  [hideDialog]="hideQrModalDialog"
  [width]="500"
>
  <div class="qr-modal-wrapper">
    <div class="qr-code-part">
      <h3>Scan QR code in any authenticator app (Google Authenticator, Duo, Authy)</h3>
      <qr-code-view
        [errorCorrectionLevel]="'High'"
        [qrdata]="qrCodeSecret$ | async"
      ></qr-code-view>
      <p class="welcome-sub-text">
        Close after QR scan is completed and your authenticator app shows One Time Password (OTP)
      </p>
    </div>
    <div class="qr-code-btns py-4">
      <button
        (click)="closeQrModal()"
        class="btn btn-default"
      >
        CLOSE
      </button>
    </div>
  </div>
</pinnakl-modal>
