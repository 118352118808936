import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { ApplicationAccessLevel, User, UserInfoApi } from '@pinnakl/shared/types';
import { getBooleanFromString } from '@pinnakl/shared/util-helpers';
import { isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {
  private users?: User[];
  private readonly _usersEndpoint = 'entities/users';

  constructor(private readonly wsp: WebServiceProvider) {}

  getAccessControlUsers(forceFetch = false): Observable<User[] | null> {
    return this.users && !forceFetch
      ? of(this.users)
      : this.wsp
          .get<UserInfoApi[]>({
            endpoint: this._usersEndpoint,
            optionsParams: {
              fields: [
                'applicationaccesslevel',
                'tradingaccess',
                'firstname',
                'lastname',
                'tokenReAuthInterval',
                'authType',
                'IsDevUser',
                'otpchannel',
                'email',
                'phone',
                'clientAdmin',
                'complianceAccess'
              ],
              filters: [
                {
                  key: 'isDevUser',
                  type: 'EQ',
                  value: ['0']
                }
              ]
            }
          })
          .pipe(
            map(users => (!isEmpty(users) ? this.setUsersFromApi(users) : null)),
            tap(users => (this.users = users.filter(user => !!user)))
          );
  }

  setUsersFromApi(usersFromApi: Partial<UserInfoApi>[]): User[] {
    return usersFromApi.map(userFromApi => {
      const user = new User(
        parseInt(userFromApi?.clientid),
        userFromApi.firstname,
        `${userFromApi.firstname || ''} ${userFromApi.lastname || ''}`,
        parseInt(userFromApi.id.toString()),
        userFromApi.lastname,
        '',
        userFromApi?.username,
        userFromApi?.email,
        userFromApi?.phone,
        userFromApi?.otpchannel,
        userFromApi?.pinnaklclientname,
        getBooleanFromString(userFromApi.complianceaccess ?? '') ?? false
      );

      user.authType = userFromApi.authtype;
      user.clientAdmin = getBooleanFromString(userFromApi.clientadmin ?? '') ?? false;
      user.isDevUser = getBooleanFromString(userFromApi.isdevuser ?? '') ?? false;
      user.tradingAccess = getBooleanFromString(userFromApi.tradingaccess ?? '') ?? false;
      user.applicationAccessLevel = userFromApi.applicationaccesslevel as ApplicationAccessLevel;

      return user;
    });
  }
}
