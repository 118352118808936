import { Component, effect, inject, Injector, OnInit, Signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CrmWatermarkSettingsStore } from '@pinnakl/shared/crm-settings/data-access';
import {
  buildWatermarkSettingsForm,
  HorizontalDistanceReferenceOptions,
  VerticalDistanceReferenceOptions,
  WatermarkSettings,
  WatermarkSettingsForm,
  WatermarkSettingsRotationFormControls
} from '@pinnakl/shared/crm-settings/domain';
import {
  InputContainerComponent,
  InputValidationComponent,
  LabelContentComponent,
  PrimeButtonComponent,
  PrimeInputNumberComponent,
  PrimeSingleDropdownComponent
} from '@pinnakl/shared/ui/prime';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'crm-settings-watermark-settings',
  standalone: true,
  templateUrl: './watermark-settings.component.html',
  imports: [
    ReactiveFormsModule,
    InputContainerComponent,
    LabelContentComponent,
    PrimeInputNumberComponent,
    InputValidationComponent,
    PrimeSingleDropdownComponent,
    PrimeButtonComponent,
    SkeletonModule
  ]
})
export class WatermarkSettingsComponent implements OnInit {
  private readonly crmWatermarkSettingsStore = inject(CrmWatermarkSettingsStore);
  private readonly injector = inject(Injector);
  readonly FormControlNames = WatermarkSettingsRotationFormControls;
  rotationForm: WatermarkSettingsForm = buildWatermarkSettingsForm();
  verticalDistanceReferenceOptions = VerticalDistanceReferenceOptions;
  horizontalDistanceReferenceOptions = HorizontalDistanceReferenceOptions;
  watermarkFonts: Signal<string[] | null> = this.crmWatermarkSettingsStore.watermarkFonts;
  watermarkSettings: Signal<WatermarkSettings | null> =
    this.crmWatermarkSettingsStore.watermarkSettings;
  watermarkSettingsLoading: Signal<boolean> =
    this.crmWatermarkSettingsStore.watermarkSettingsLoading;
  watermarkFontsSizes: number[] = Array.from({ length: 46 }, (v, k) => k + 5);

  ngOnInit(): void {
    this.crmWatermarkSettingsStore.loadWatermarkSettings();
    this.crmWatermarkSettingsStore.loadWatermarkFonts();
    effect(
      () => {
        const watermarkSettings = this.watermarkSettings();
        if (watermarkSettings && !this.watermarkSettingsLoading()) {
          this.rotationForm.patchValue(watermarkSettings);
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  submit(): void {
    if (!this.rotationForm.valid) return;
    const data = this.rotationForm.getRawValue() as WatermarkSettings;
    this.crmWatermarkSettingsStore.updateWatermarkSettings(data);
  }
}
