<div
  *ngIf="form"
  [formGroup]="form"
  class="section-wrapper"
>
  <div>
    <div class="section-title">email provider</div>
    <div class="section-content">
      <div>
        <span
          [class.border-active]="form.get('clientType').value === clientType.O365"
          class="radio-button"
        >
          <span class="icon-label-block">
            <img src="/assets/fonts/icons/microsoft.svg" />
            <span>microsoft 365</span>
          </span>
        </span>
        <span
          [class.border-active]="form.get('clientType').value === clientType.Gsuite"
          class="radio-button"
        >
          <span class="icon-label-block">
            <img src="/assets/fonts/icons/gsuite.svg" />
            <span>suite</span>
          </span>
        </span>
      </div>

      <div class="section-form form">
        <pinnakl-input
          [disabled]="true"
          [form]="form"
          controlName="tenantId"
          label="tenant id"
          labelClass="text-upper-case"
          type="text"
        >
        </pinnakl-input>
        <pinnakl-input
          [disabled]="true"
          [form]="form"
          controlName="applicationId"
          label="application id"
          labelClass="text-upper-case"
          type="text"
        >
        </pinnakl-input>
        <pinnakl-input
          [disabled]="true"
          [form]="form"
          controlName="apiKeyValue"
          label="api key value"
          labelClass="text-upper-case"
          type="text"
        >
        </pinnakl-input>
      </div>

      <div class="section-actions">
        <span
          [ngClass]="testEmail.isError ? 'color-red' : 'color-green'"
          class="error-msg text-upper-case"
          >{{ testEmail.message }}</span
        >
        <button
          (click)="openTestEmailModal()"
          class="button-container blue action-button"
        >
          test email
        </button>
      </div>
    </div>
    <hr class="hr" />
    <div class="section-title">email campaign configuration</div>
    <div class="form">
      <div class="toggle-container">
        <pinnakl-input
          [form]="form"
          [showValidation]="false"
          checkBoxStyle="toggle"
          controlName="saveSentItemsInOutlookEnabled"
          label="Save Sent emails in Outlook"
          type="boolean"
        >
        </pinnakl-input>
      </div>
      <div class="toggle-container mb-4">
        <pinnakl-input
          [form]="form"
          [showValidation]="false"
          checkBoxStyle="toggle"
          controlName="testCampaignsBeforeSending"
          label="Testing campaigns before sending"
          type="boolean"
        >
        </pinnakl-input>
      </div>
    </div>
  </div>
  <div class="section-actions">
    <button
      (click)="saveCRMClientConfig()"
      [disabled]="!form.valid"
      class="button-container blue action-button"
    >
      save
    </button>
  </div>
</div>

<div class="border-round border-1 border-bluegray-200 p-3 mt-4 flex flex-column w-min">
  <div class="font-semibold white-space-nowrap">Office 365 Integration</div>

  @if (isNylasIntegrationLoading()) {
    <pinnakl-local-spinner-simple-mode-container
      class="block"
      containerHeight="80px"
      containerWidth="100%"
      size="50px"
    ></pinnakl-local-spinner-simple-mode-container>
  } @else {
    @if (isIntegratedAlready()) {
      <div class="mt-3 text-xs">
        Status: <span class="text-green-600 font-semibold text-xs">Connected</span>
      </div>
    } @else {
      <prime-button
        (onClick)="integrateOffice365WithNylas()"
        class="w-full mt-3"
        label="Integrate"
        severity="primary"
        size="sm"
        styleClass="w-full"
      ></prime-button>
    }
  }
</div>
