import { inject, Injectable, signal } from '@angular/core';
import { EnvironmentService } from '@pinnakl/core/environment';
import { PinnaklHttpService, WebServiceProvider } from '@pinnakl/core/web-services';
import { EmailsCollector, NylasEmailsCollectorConnectionStatus } from '@pinnakl/shared/types';
import { getErrorMessage } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NylasEmailsCollectorService implements EmailsCollector {
  private readonly environment = inject(EnvironmentService);
  private readonly wsp = inject(WebServiceProvider);
  private readonly toast = inject(PinnaklUIToastMessage);
  private readonly pinnaklHttpService = inject(PinnaklHttpService);

  private readonly emailsCollectorUrl = this.environment.get<string>('emailsCollectorUrl');
  readonly isIntegratedAlready = signal(false);

  async getIntegrationStatus(): Promise<NylasEmailsCollectorConnectionStatus> {
    try {
      const checkStatusResponse = await firstValueFrom(this.getStatus());
      return checkStatusResponse.status;
    } catch (e) {
      this.toast.error('Check Nylas integration status error. ' + getErrorMessage('', e));
      throw e;
    }
  }

  async integrate(redirectUrl?: string): Promise<void> {
    try {
      window.location.href = await firstValueFrom(this.getAuthLocationUrl(redirectUrl));
    } catch (e) {
      this.toast.error('Nylas integration error. ' + getErrorMessage('', e));
      throw e;
    }
  }

  setIntegrationStatus(status: NylasEmailsCollectorConnectionStatus): void {
    this.isIntegratedAlready.set(status === NylasEmailsCollectorConnectionStatus.Valid);
  }

  private getStatus(): Observable<{ status: NylasEmailsCollectorConnectionStatus }> {
    return this.wsp.get<{ status: NylasEmailsCollectorConnectionStatus }>({
      endpoint: 'status',
      customHostUrl: this.emailsCollectorUrl
    });
  }

  private getAuthLocationUrl(redirectUrl?: string): Observable<string> {
    return this.pinnaklHttpService
      .getObservableWithFullResponse<null>({
        endpoint: 'auth',
        customHostUrl: this.emailsCollectorUrl,
        otherParams: {
          redirectUrl: redirectUrl ?? window.location.href
        }
      })
      .pipe(
        map(resp => {
          const urlToRedirect = resp.headers.get('Location');
          if (urlToRedirect) {
            return urlToRedirect;
          }
          throw new Error('Nylas auth location url not found');
        })
      );
  }
}
