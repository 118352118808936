import { patchState, signalStore, type, withState } from '@ngrx/signals';
import { withEntities } from '@ngrx/signals/entities';
import {
  CustomAttribute,
  CustomAttributeFeature,
  CustomAttributeFilter
} from '@pinnakl/shared/custom-attributes/domain';
import {
  payload,
  withCallState,
  withDataService,
  withDevtools,
  withRedux
} from '@pinnakl/shared/signal-store';
import { CommonCustomAttributesApiService } from './common-custom-attributes-api.service';

// use camelCase for store key
const customAttributesStoreKey = 'commonSecurityCustomAttributes';
export const CommonSecurityCustomAttributesStore = signalStore(
  { providedIn: 'root' },
  withState({
    sidebarOpened: false,
    showButton: true
  }),
  withDevtools(customAttributesStoreKey),
  // Examples are commented out
  withRedux({
    actions: {
      public: {
        toggleSidebar: payload<{ open: boolean }>(),
        toggleShowButton: payload<{ show: boolean }>()
      },
      private: {}
    },
    reducer(actions, on) {
      on(actions.toggleSidebar, ({ open }, state) => {
        patchState(state, { sidebarOpened: open });
      });
      on(actions.toggleShowButton, ({ show }, state) => {
        patchState(state, { showButton: show });
      });
    },
    effects() {
      return {};
    }
  }),
  withCallState(),
  withEntities({
    entity: type<CustomAttribute>()
  }),
  withDataService<CustomAttribute, CustomAttributeFilter, CommonCustomAttributesApiService>({
    dataServiceType: CommonCustomAttributesApiService,
    filter: {
      feature: CustomAttributeFeature.Security
    }
  })
);
