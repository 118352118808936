import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { UserService } from '@pinnakl/core/data-providers';
import { EMAIL_PATTERN, PHONE_PATTERN } from '@pinnakl/shared/constants';
import { User, userTimeZones } from '@pinnakl/shared/types';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';

@Component({
  selector: 'account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnChanges {
  readonly isEmailFormControlDisabled = true;
  readonly availableTimezones = userTimeZones;

  cancelConfirmationVisible = false;
  accountForm: UntypedFormGroup;

  @Input() user: User;
  @Output() updateUser = new EventEmitter<User>();

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly userService: UserService,
    private readonly spinner: PinnaklSpinnerService,
    private readonly toastr: PinnaklUIToastMessage
  ) {
    this.accountForm = new FormGroup({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      username: new FormControl(
        {
          value: null,
          disabled: true
        },
        {
          validators: Validators.required
        }
      ),
      email: new FormControl(
        {
          value: null,
          disabled: true
        },
        {
          validators: [Validators.required, Validators.pattern(EMAIL_PATTERN)]
        }
      ),
      phone: new FormControl(null, [Validators.required, Validators.pattern(PHONE_PATTERN)]),
      timezone: new FormControl(null)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      this.resetForm();
    }
  }

  showFormCancelConfirmation(): void {
    this.cancelConfirmationVisible = true;
  }

  cancelReset(): void {
    this.cancelConfirmationVisible = false;
  }

  resetForm(): void {
    this.updateForm();
    this.cancelReset();
  }

  onSubmit(): void {
    const userAuthToSave = {
      id: this.user?.id.toString(),
      firstName: this.accountForm.get('firstName')?.value,
      lastName: this.accountForm.get('lastName')?.value,
      phone: this.accountForm.get('phone')?.value,
      timeZone: this.accountForm.get('timezone')?.value
    };

    this.spinner.spin();
    this.userService
      .putUser(userAuthToSave)
      .then(() => {
        this.updateUser.emit({
          ...this.user,
          ...userAuthToSave,
          id: this.user?.id
        });
        this.spinner.stop();
        this.toastr.success('Successfully changed');
      })
      .catch(() => {
        this.spinner.stop();
        this.toastr.error('Error in changing');
      });
  }

  private updateForm(): void {
    this.accountForm.patchValue({
      firstName: this.user?.firstName,
      lastName: this.user?.lastName,
      username: this.user?.username,
      email: this.user?.email,
      phone: this.user?.phone,
      timezone: this.user?.timezone
    });
  }
}
