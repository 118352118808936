import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { CrmVdrConfig } from '@pinnakl/vdr/domain';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VdrConfigApiService {
  private readonly _crmVdrConfigEndpoint = 'v3/entities/crm_portal_config';

  constructor(private readonly wsp: WebServiceProvider) {}

  postCrmVdrConfig(config: Partial<CrmVdrConfig>): Observable<CrmVdrConfig> {
    return this.wsp.post({
      endpoint: this._crmVdrConfigEndpoint,
      body: config
    });
  }

  putCrmVdrConfig(config: Partial<CrmVdrConfig>): Observable<CrmVdrConfig> {
    return this.wsp.put({
      endpoint: this._crmVdrConfigEndpoint,
      body: config
    });
  }

  getCrmVdrConfigs(): Observable<CrmVdrConfig[]> {
    return this.wsp.get({
      endpoint: this._crmVdrConfigEndpoint
    });
  }
}
