import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as vdrConfig from './vdr-config/vdr-config.reducer';

export interface State {
  vdrConfig: vdrConfig.State;
}

export const reducers: ActionReducerMap<State> = {
  vdrConfig: vdrConfig.reducer
};

const selectVdrFeature = createFeatureSelector<State>('vdrConfig');

const selectVdrConfigState = createSelector(selectVdrFeature, (state: State) => state.vdrConfig);

const selectVdrConfig = createSelector(selectVdrConfigState, vdrConfig.selectVdrConfig);

const selectVdrConfigError = id =>
  createSelector(selectVdrConfigState, vdrConfig.selectVdrConfigError);

const selectVdrConfigLoaded = createSelector(selectVdrConfigState, vdrConfig.selectVdrConfigLoaded);

const selectVdrConfigLoading = createSelector(
  selectVdrConfigState,
  vdrConfig.selectVdrConfigLoading
);

export const vdrConfigQuery = {
  selectVdrConfig,
  selectVdrConfigError,
  selectVdrConfigLoaded,
  selectVdrConfigLoading
};
